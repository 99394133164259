import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  FirstPageButton, PrevButton, NextButton, LastPageButton,
} from 'components/Buttons/PaginationButtons';

export function TopicPagination({ onSelectPage, style, containerId }) {
  const { totalPages } = useSelector((state) => state.topicPagination);
  const { pageNumber } = useParams();

  const currentPage = +pageNumber || 1;

  return (
    <Box
      sx={{ ...style }}
      id={containerId}
    >
      <FirstPageButton
        disabled={!totalPages || currentPage === 1}
        onClick={() => {
          onSelectPage(1);
        }}
      />
      <PrevButton
        disabled={!totalPages || currentPage === 1}
        onClick={() => {
          onSelectPage(currentPage - 1);
        }}
      />
      <NextButton
        disabled={!totalPages || currentPage === totalPages}
        onClick={() => {
          onSelectPage(currentPage + 1);
        }}
      />
      <LastPageButton
        disabled={!totalPages || currentPage === totalPages}
        onClick={() => {
          onSelectPage(totalPages);
        }}
      />
    </Box>
  );
}
