import React, { useState } from 'react';
import { IconButton, TextField } from '@mui/material';
import { PhotoCamera, SaveAs } from '@mui/icons-material';
import { updateUserProfile, uploadUserAvatar, uploadUserBackground } from 'services/providers/pandlr.provider';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, setSnackbar } from 'redux/slicers';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';

export function UpdateUserForm() {
  const [avatarUpload, setAvatarUpload] = useState(null);
  const [backgroundUpload, setBackgroundUpload] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useSelector((state) => state.user);
  const INITIAL_STATE = {
    name: user.name || '',
    avatar: '',
    background: '',
  };
  const [data, setData] = useState(INITIAL_STATE);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevState) => ({
      ...prevState,
      [name]: !value || value.length < 3 || value.length > 20,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
  };

  const uploadAvatar = async () => {
    const formData = new FormData();
    formData.append('image', data.avatar);

    const res = await uploadUserAvatar(formData);

    if (!res.success) {
      dispatch(setSnackbar({ message: res.message, status: 'error' }));
      setAvatarUpload(null);
      setIsSubmitting(false);
      return null;
    }

    setAvatarUpload(res.data);

    return res.data;
  };

  const uploadBackground = async () => {
    const formData = new FormData();
    formData.append('image', data.background);

    const res = await uploadUserBackground(formData);

    if (!res.success) {
      dispatch(setSnackbar({ message: res.message, status: 'error' }));
      setBackgroundUpload(null);
      setIsSubmitting(false);
      return null;
    }

    setBackgroundUpload(res.data.path);

    return res.data.path;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let image = null;
    let background = null;

    if (avatarUpload?.path) {
      image = avatarUpload;
    } else if (data.avatar) {
      image = await uploadAvatar();
    } else {
      image = {
        path: user.avatarThumb,
        path300: user.avatar,
      };
    }

    if (backgroundUpload?.path) {
      background = backgroundUpload;
    } else if (data.background) {
      background = await uploadBackground();
    } else {
      background = user.background;
    }

    if (!image) return;

    const result = await updateUserProfile({
      ...data,
      avatar: image.path300,
      avatarThumb: image.path,
      background,
    });

    if (!result.success) {
      setIsSubmitting(false);
      setErrors((prevState) => ({
        ...prevState,
        username: result.data.message,
      }));

      dispatch(setSnackbar({ message: result.data.message, status: 'error' }));

      return;
    }

    dispatch(setSnackbar({ message: 'User has been updated', status: 'success' }));

    dispatch(setUser(result.data));
    setAvatarUpload(null);

    setIsSubmitting(false);
    navigate('/');
  };

  const AVATAR_URL = data.avatar ? URL.createObjectURL(data.avatar) : `${process.env.REACT_APP_IMAGE_URL}/${user.avatar}`;
  const BANNER_URL = data.background ? URL.createObjectURL(data.background) : `${process.env.REACT_APP_IMAGE_URL}/${user.background}`;

  return (
    <>
      <S.UserContainer>
        <S.UserImagesContainer>
          <S.BannerImage src={BANNER_URL} />
          <S.UserAvatarContainer>
            <S.UserImage
              src={AVATAR_URL}
              alt="teste"
            />
          </S.UserAvatarContainer>
          <S.UserInfosContainer>
            <S.UserName>
              {user?.name}
            </S.UserName>
          </S.UserInfosContainer>
        </S.UserImagesContainer>
        <S.UserDetailsContainer>
          <S.UserNameMobile>
            {user?.name}
          </S.UserNameMobile>
          <S.UserUsername>
            {user?.username}
          </S.UserUsername>
        </S.UserDetailsContainer>
      </S.UserContainer>

      <S.UpdateUserFormContainer>
        <S.Form onSubmit={onSubmit}>
          <S.FormContainer>
            <S.Input>
              <TextField
                variant="outlined"
                fullWidth
                label="Nome *"
                name="name"
                value={data.name}
                onChange={handleChange}
                error={!!errors.name}
              />
            </S.Input>

            <S.Input>
              <S.LabelUpload>
                Carregue uma imagem para seu avatar
              </S.LabelUpload>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input name="avatar" onChange={handleFileChange} hidden accept="image/*" type="file" />
                <PhotoCamera />
              </IconButton>
            </S.Input>

            <S.Input>
              <S.LabelUpload>
                Carregue uma imagem para seu background
              </S.LabelUpload>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input name="background" onChange={handleFileChange} hidden accept="image/*" type="file" />
                <PhotoCamera />
              </IconButton>
            </S.Input>

            <S.FormActions>
              <S.LoadingButton
                disabled={((
                  !!data.name && !data.avatar && data.name === user.name && !data.background)
                  || isSubmitting
                )}
                type="submit"
                loading={isSubmitting}
                endIcon={<SaveAs />}
              >
                Salvar
              </S.LoadingButton>
            </S.FormActions>

          </S.FormContainer>
        </S.Form>
      </S.UpdateUserFormContainer>
    </>

  );
}
