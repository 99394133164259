/* eslint-disable prefer-destructuring */
import {
  FacebookEmbed,
  InstagramEmbed,
  PinterestEmbed,
  TikTokEmbed,
  TwitterEmbed,
} from 'react-social-media-embed';
import SpotifyEmbed from 'react-spotify-embed';
import { useMediaQuery } from '@mui/material';

function YouTubeEmbed({ url }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const video = {
    code: '',
  };

  if (url?.includes('youtu.be')) {
    video.code = url.split('be/')[1];
  }
  if (url?.includes('youtube.com/watch')) {
    const code = url.split('v=')[1];
    if (code.includes('&')) {
      video.code = code.split('&')[0];
    } else {
      video.code = code;
    }
  }

  return (
    <iframe
      width="100%"
      height={isMobile ? '200px' : '500px'}
      src={`https://www.youtube.com/embed/${video.code}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
}

function XVideosEmbed({ url }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const videoId = url.split('/video')[1].split('/')[0];

  return (
    <iframe
      title="XVideos video player"
      src={`https://www.xvideos.com/embedframe/${videoId}`}
      height={isMobile ? '200px' : '500px'}
      width="100%"
      allowFullScreen
    />
  );
}

function PornHubEmbed({ url }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const videoId = url.split('viewkey=')[1];

  return (
    <iframe
      title="PornHub video player"
      src={`https://www.pornhub.com/embed/${videoId}`}
      height={isMobile ? '200px' : '500px'}
      width="100%"
      allowFullScreen
    />
  );
}

function XHamsterEmbed({ url }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const removeString = url.split('?')[0];
  const videoId = removeString.substring(removeString.length - 7);

  return (
    <iframe
      title="XHamster video player"
      src={`https://xhamster.com/embed/${videoId}`}
      height={isMobile ? '200px' : '500px'}
      width="100%"
      allowFullScreen
    />
  );
}

function VimeoEmbed({ url }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const videoId = url.split('vimeo.com/')[1];

  return (
    <iframe
      title="Vimeo video player"
      src={`https://player.vimeo.com/video/${videoId}`}
      height={isMobile ? '200px' : '500px'}
      width="100%"
      allowFullScreen
    />
  );
}

function SpotifyEmbedComponent({ url }) {
  return (
    <SpotifyEmbed
      link={url}
      wide
    />
  );
}

function SoundCloudEmbed({ url }) {
  return (
    <iframe
      title="SoundCloud music Player"
      width="100%"
      src={`https://w.soundcloud.com/player/?url=${url}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}
    />
  );
}

const embedComponents = {
  facebook: FacebookEmbed,
  youtu: YouTubeEmbed,
  instagram: InstagramEmbed,
  twitter: TwitterEmbed,
  tiktok: TikTokEmbed,
  pinterest: PinterestEmbed,
  spotify: SpotifyEmbedComponent,
  soundcloud: SoundCloudEmbed,
  xvideos: XVideosEmbed,
  pornhub: PornHubEmbed,
  xhamster: XHamsterEmbed,
  vimeo: VimeoEmbed,
};

const prefixes = {
  youtu: ['youtu.be', 'youtube.com/watch'],
  twitter: ['status'],
  instagram: ['instagram.com/p', 'instagram.com/reel'],
  facebook: ['fb.watch', 'facebook.com/photo', 'posts'],
  tiktok: ['tiktok', 'video/'],
  pinterest: ['pin'],
  spotify: ['open.spotify.com'],
  soundcloud: ['soundcloud.com'],
  xvideos: ['xvideos.com/video'],
  pornhub: ['pornhub.com/view_video'],
  xhamster: ['xhamster.com/videos'],
};

const excludePrefixes = {
  youtu: ['youtube.com/playlist'],
  twitter: ['twitter.com/i/lists'],
  instagram: ['instagram.com/stories'],
  facebook: ['groups'],
  tiktok: ['vm.tiktok'],
  pinterest: [],
  spotify: [],
  soundcloud: [],
  xvideos: [],
  pornhub: [],
  xhamster: [],
};

function isEmbeddable(url) {
  if (!url) return false;
  return Object.keys(prefixes).find((platform) => (
    url.includes(platform) && prefixes[platform]?.some((p) => url.includes(p))
    && !excludePrefixes[platform]?.some((p) => url.includes(p))
  )) || false;
}

function checkTextUrl(textWithUrl) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const splitText = textWithUrl.split(' ');
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  if (splitText.length > 1) {
    const textsWithUrls = splitText.filter((text) => urlRegex.test(text));
    const textsWithoutUrls = splitText.filter((text) => !urlRegex.test(text));

    const embeds = textsWithUrls.map((text) => {
      const url = text.match(urlRegex)[0];
      const platform = isEmbeddable(url);
      const EmbedComponent = embedComponents[platform];

      return platform ? (
        <EmbedComponent
          width={isMobile ? '100%' : '500px'}
          url={url}
        />
      ) : <a target="_blank" rel="nofollow noreferrer" href={url}>{url}</a>;
    });

    const texts = textsWithoutUrls.join(' ');

    return [...embeds.slice(1), <p key={Math.random()}>{texts}</p>];
  }

  const platform = isEmbeddable(textWithUrl);

  const EmbedComponent = embedComponents[platform];

  return platform
    ? (
      <EmbedComponent
        width={isMobile ? '100%' : '500px'}
        url={textWithUrl}
      />
    )
    : <a target="_blank" rel="nofollow noreferrer" href={textWithUrl}>{textWithUrl}</a>;
}

function Embed({ url }) {
  return checkTextUrl(url);
}

export default Embed;
