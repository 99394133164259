import {
  Box,
  Button,
  ListItemIcon, MenuItem, TextareaAutosize,
} from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { forumBanUser } from 'services/providers/pandlr.provider';
import BlockIcon from '@mui/icons-material/Block';
import * as S from './styles';

export function BanModal({ userId }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { groupId } = useSelector((state) => state.topicDetails);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newBan = {
      groupId,
      userId,
      reason: e.target.reason.value,
    };
    const response = await forumBanUser(newBan);
    handleClose();
    if (response.success) {
      return alert('User banned');
    } return alert('Error banning user');
  };

  return (
    <>
      <MenuItem onClick={handleOpen}>
        <ListItemIcon>
          <BlockIcon fontSize="small" />
        </ListItemIcon>
        Banir
      </MenuItem>
      <S.Modal
        open={open}
        handleClose={handleClose}
        modalName={S.MODAL_STYLES.BAN_USER_MODAL}
        title="Banir Usuário"
      >
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 1,
            }}
          >
            <TextareaAutosize
              style={{
                padding: '5px',
                borderRadius: '5px',
                maxWidth: '600px',
              }}
              id="reason"
              placeholder="Motivo do banimento"
              required
              minRows={3}
            />
            <br />
            <Button
              id="submit"
              type="submit"
              variant="contained"
            >
              Banir
            </Button>
          </Box>
        </form>
      </S.Modal>
    </>
  );
}
