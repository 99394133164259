import {
  IconButton, ListItemIcon, MenuItem, Tooltip,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

export function MentionsBtn({ isMenuItem = false }) {
  const navigate = useNavigate();

  return isMenuItem ? (
    <MenuItem
      onClick={() => navigate('/mentions')}
    >
      <ListItemIcon>
        <AlternateEmailIcon />
      </ListItemIcon>
      Menções
    </MenuItem>
  ) : (
    <Tooltip arrow title="Menções">
      <IconButton
        color="primary"
        aria-label="mention messages"
        component="label"
        onClick={() => navigate('/mentions')}
      >
        <AlternateEmailIcon />
      </IconButton>
    </Tooltip>
  );
}
