import React from 'react';
import UserInfos from './UserInfos';
import { UserInteractions } from './UserInteractions';

export function UserProfile() {
  return (
    <div>
      <UserInfos />
      <UserInteractions />
    </div>
  );
}
