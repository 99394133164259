import { Fragment } from 'react';
import {
  ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Box, Divider,
} from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import { Link, useNavigate } from 'react-router-dom';

export function ForumSingleTopic({ topic, groupSlug, isFeatured = false }) {
  const LINK_TO_TOPIC = (details) => `/forum/${groupSlug}/topic/${details.slug}/${details.id}`;
  const LINK_TO_LAST_REPLY = (details) => `/forum/${groupSlug}/topic/${details.slug}/${details.id}/page/${details.currentLastPage || 1}/#post-${details.lastUpdateId}`;
  const navigate = useNavigate();
  return (
    <Fragment key={`topic-${topic.id}`}>
      <ListItem sx={{ overflow: 'hidden' }}>
        <ListItemAvatar
          sx={{
            alignSelf: 'flex-start',
            mt: '3px',
            ml: { xs: -1, sm: 0 },
          }}
        >
          <Avatar
            src={`${process.env.REACT_APP_IMAGE_URL}${topic.topicAuthor.avatarThumb}`}
            alt={topic.topicAuthor.username}
            variant="rounded"
            sx={{
              width: 48, height: 48, mt: 0.5, cursor: 'pointer',
            }}
            onClick={() => navigate(`/user/${topic.topicAuthor.username}`)}
          />
        </ListItemAvatar>
        <ListItemText
          sx={{
            mt: 0.5,
          }}
          primary={(
            <Link
              style={{ textDecoration: 'none' }}
              to={LINK_TO_TOPIC(topic)}
            >
              <Typography
                sx={{ display: 'inline', fontWeight: 'bold' }}
                component="span"
                variant="body1"
                color="text.primary"
              >
                {topic.title}
              </Typography>
              {isFeatured && (
                <PushPinIcon
                  sx={{
                    width: 16,
                    height: 16,
                    ml: 0.5,
                    color: 'secondary.main',
                  }}
                />
              )}
            </Link>
          )}
          secondary={(
            <Box
              component="span"
              display="block"
              mt="8px"
            >
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {topic.replies.toLocaleString()}
                {' '}
                posts
              </Typography>
              {' '}
              -
              {' '}
              <Typography
                sx={{ display: 'inline', cursor: 'pointer' }}
                component="span"
                variant="body3"
                color="text.secondary"
                onClick={() => navigate(`/user/${topic.lastPostAuthor.username}`)}
              >
                Último post @
                {topic.lastPostAuthor.username}
              </Typography>
              {' '}
              <Typography
                sx={{ display: { xs: 'none', sm: 'inline' } }}
                component="span"
                variant="body3"
                color="text.secondary"
              >
                -
              </Typography>
              {' '}
              <Link
                style={{ textDecoration: 'none' }}
                to={LINK_TO_LAST_REPLY(topic)}
              >
                <Typography
                  sx={{ display: { xs: 'block', sm: 'inline' } }}
                  component="span"
                  variant="body3"
                  color="text.secondary"
                >
                  {topic.lastUpdateTimeStamp}
                </Typography>
              </Link>
            </Box>
          )}
        />
      </ListItem>
      <Divider component="li" variant="middle" />
    </Fragment>
  );
}
