import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoadingProfile, setUserProfile } from 'redux/slicers';
import * as S from './styles';
import { getUserByUsername } from '../../services/providers/pandlr.provider';

export default function UserInfos() {
  const { username } = useParams();
  const { user, isLoading } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();

  const getUser = async () => {
    dispatch(setIsLoadingProfile(true));
    const res = await getUserByUsername(username);
    if (res.success) {
      document.title = `${res.data.name} - Pandlr`;
      dispatch(setUserProfile(res.data));
      dispatch(setIsLoadingProfile(false));
    }
  };

  useEffect(() => {
    getUser();
    return () => {
      dispatch(setUserProfile({}));
    };
  }, [username]);

  const AVATAR_URL = `${process.env.REACT_APP_IMAGE_URL}${user?.avatar}`;
  const BANNER_URL = user?.background ? `${process.env.REACT_APP_IMAGE_URL}${user?.background}` : 'https://pbs.twimg.com/profile_banners/787361839827062788/1670721542/1500x500';

  return (
    isLoading ? <S.ProfileSkeleton /> : (
      <S.UserContainer>
        <S.UserImagesContainer>
          <S.BannerImage src={BANNER_URL} />
          <S.UserAvatarContainer>
            <S.UserImage
              src={AVATAR_URL}
              alt={user?.name}
            />
          </S.UserAvatarContainer>
          <S.UserInfosContainer>
            <S.UserName>
              {user?.name}
            </S.UserName>
          </S.UserInfosContainer>
        </S.UserImagesContainer>
        <S.UserDetailsContainer>
          <S.UserNameMobile>
            @
            {' '}
            {user?.name}
          </S.UserNameMobile>
          <S.UserUsername>
            @
            {' '}
            {user?.username}
          </S.UserUsername>
        </S.UserDetailsContainer>
      </S.UserContainer>
    )
  );
}
