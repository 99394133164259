import React, { useEffect } from 'react';
import { Footer, Header } from 'components';
import { UpdateForumForm } from 'components/Forms';
import { useParams } from 'react-router-dom';
import * as S from './styles';

export function ManageForum() {
  const { slug } = useParams();

  useEffect(() => {
    document.title = `manage forum - ${slug}`;
  }, []);

  return (
    <>
      <Header />
      <S.ManageForumContainer>
        <UpdateForumForm />
      </S.ManageForumContainer>
      <Footer />
    </>
  );
}
