import { useState, useEffect } from 'react';
import { addNewGifToGifPocket, getGifPocketList } from 'services/providers/pandlr.provider';
import { Box, TextField, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar, setAddToGifPocket, setGifPocket } from 'redux/slicers';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import * as S from './styles';

export function GifPocketFastAddModal() {
  const [shortcut, setShortcut] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const imageUrl = useSelector((state) => state.addToGifPocket);

  useEffect(() => {
    if (imageUrl.length > 0) {
      setOpen(true);
    }
  }, [imageUrl]);

  const handleClose = () => {
    setOpen(false);
    setShortcut('');
    dispatch(setAddToGifPocket(''));
  };

  const handleAddNewGifToGifPocket = async (event) => {
    event.preventDefault();
    setLoading(true);
    const result = await addNewGifToGifPocket({ imageUrl, shortcut });
    if (result.success) {
      const fetchList = await getGifPocketList();
      if (fetchList.success) {
        dispatch(setGifPocket(fetchList.data));
      }
      dispatch(setSnackbar({
        status: 'success',
        message: 'Gif adicionado com sucesso!',
      }));
      setLoading(false);
      return handleClose();
    } dispatch(setSnackbar({
      status: 'error',
      message: 'Erro ao adicionar gif!',
    }));
    setLoading(false);
    return handleClose();
  };

  return (
    <S.Modal
      open={open}
      handleClose={handleClose}
      modalName={S.MODAL_STYLES.FAST_ADD_GIF_POCKET_MODAL}
      title="Gif Pocket"
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ mb: 5 }}>
        <TextField
          id="shortcut"
          label="Atalho"
          variant="outlined"
          size="small"
          sx={{ mb: 2 }}
          fullWidth
          required
          autoFocus
          value={shortcut}
          onChange={(event) => setShortcut(event.target.value)}
        />
        <LoadingButton
          loading={loading}
          variant="contained"
          size="large"
          type="submit"
          endIcon={<SaveIcon />}
          loadingPosition="end"
          onClick={handleAddNewGifToGifPocket}
        >
          Salvar
        </LoadingButton>
      </Box>
    </S.Modal>
  );
}
