import Embed from 'components/Embed/Embed';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { v4 as uuidv4 } from 'uuid';

export const gifOptionsEvent = (UUID, setShowGifOptions) => {
  const image = document.getElementById(UUID);
  const rect = image.getBoundingClientRect();
  const positions = {
    top: rect.top + window.scrollY,
    left: rect.left + window.scrollX,
  };
  setShowGifOptions({
    show: true, image: image.src, UUID, positions, element: image,
  });
};

const renderEmbed = (url) => <Embed url={url} />;
const renderLink = (text) => <a href={text}>{text}</a>;

export const purifyContent = (content, setShowGifOptions) => {
  const cleanHtmlTopicContent = DOMPurify.sanitize(content);

  let linksCounter = 0;

  const replyContent = parse(cleanHtmlTopicContent, {
    replace: (domNode) => {
      if (domNode.type === 'text' && /https?:\/\//.test(domNode.data)) {
        if (linksCounter >= 3) {
          return renderLink(domNode.data);
        }
        linksCounter += 1;
        return renderEmbed(domNode.data);
      }
      if (domNode.name === 'img' && domNode.attribs.src) {
        const UUID = uuidv4();
        domNode.attribs.id = `${UUID}`;
        domNode.attribs.style = 'cursor: pointer;';
        domNode.attribs.onClick = () => gifOptionsEvent(UUID, setShowGifOptions);
      }
      return domNode;
    },
  });
  return replyContent;
};
