import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/slicers';
import { useNavigate, useParams } from 'react-router-dom';
import { createNewTopic } from 'services/providers/pandlr.provider';
import { TextEditorLightFixed, TextEditorDarkFixed, GifPocketModal } from 'components';
import { TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import * as S from './styles';

export function NewTopicModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { darkMode } = useSelector((state) => state);
  const { id: forumId } = useSelector((state) => state.forumDetails);
  const dispatch = useDispatch();
  const { slug } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const newTopic = {
      title: e.target.title.value,
      content: value,
      forumId,
    };
    const response = await createNewTopic(newTopic);
    if (response.success) {
      dispatch(setSnackbar({ status: 'success', message: 'Topic created successfully' }));
      return navigate(`/forum/${slug}/topic/${response.data.slug}/${response.data.id}`);
    }
    dispatch(setSnackbar({ status: 'error', message: response.data.message }));
    return setLoading(false);
  };

  return (
    <>
      <S.Button handleOpen={handleOpen}>
        Criar tópico
      </S.Button>
      <S.Modal
        open={open}
        handleClose={handleClose}
        modalName={S.MODAL_STYLES.NEW_TOPIC_MODAL}
        title="Criar tópico"
      >
        <form onSubmit={handleSubmit}>
          <TextField
            id="title"
            label="Topic title"
            variant="outlined"
            size="small"
            sx={{ mb: 2 }}
            autoFocus
            fullWidth
            required
          />
          {
            darkMode.active
              ? <TextEditorDarkFixed setValue={setValue} value={value} />
              : <TextEditorLightFixed setValue={setValue} value={value} />
          }
          <GifPocketModal isCreatingTopic />
          <LoadingButton
            loading={loading}
            variant="contained"
            size="large"
            type="submit"
            sx={{ mt: 1 }}
            endIcon={<SendIcon />}
            loadingPosition="end"
          >
            Criar
          </LoadingButton>
        </form>
      </S.Modal>
    </>
  );
}
