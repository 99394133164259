import { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getGroupDetails, joinGroup, leaveGroup } from 'services/providers/pandlr.provider';
import { useDispatch, useSelector } from 'react-redux';
import { setForumDetails, setSnackbar } from 'redux/slicers';
import {
  Box, Avatar, AvatarGroup, IconButton, Stack,
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import GavelIcon from '@mui/icons-material/Gavel';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BlockIcon from '@mui/icons-material/Block';
import Tooltip from '@mui/material/Tooltip';

import { ForumModeratosMenu } from 'components/Menus';
import * as S from './styles';

export function ForumDetails() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { forumDetails, user } = useSelector((state) => state);

  const isMember = forumDetails.userCredentials?.isAccepted;

  useEffect(() => {
    const fetchGroupDetails = async () => {
      const result = await getGroupDetails(slug, user.user?.id);
      document.title = `${result.data.name} - Pandlr`;
      dispatch(setForumDetails(result.data));
    };
    fetchGroupDetails();
  }, [slug]);

  const handleJoinGroup = async () => {
    dispatch(setForumDetails({ ...forumDetails, userCredentials: { isAccepted: true } }));
    const result = await joinGroup(forumDetails.id);
    if (result.success) {
      return dispatch(setSnackbar({ open: true, message: 'You have joined the group' }));
    }
    dispatch(setForumDetails({ ...forumDetails, userCredentials: { isAccepted: false } }));
    return dispatch(setSnackbar({ open: true, message: result.data.message, status: 'error' }));
  };

  const handleLeaveGroup = async () => {
    dispatch(setForumDetails({ ...forumDetails, userCredentials: { isAccepted: false } }));
    const result = await leaveGroup(forumDetails.id);
    if (result.success) {
      return dispatch(setSnackbar({ open: true, message: 'You have left the group' }));
    }
    dispatch(setForumDetails({ ...forumDetails, userCredentials: { isAccepted: true } }));
    return dispatch(setSnackbar({ open: true, message: result.data.message, status: 'error' }));
  };

  const MODERATORS = forumDetails?.moderators;
  const MODERATORS_QTY = forumDetails?.moderators?.length;
  const getModeratorsSize = () => {
    if (MODERATORS_QTY > 14) {
      return -12.7;
    }
    if (MODERATORS_QTY > 7) {
      return -8.7;
    } return -5.5;
  };
  const MODERATORS_SIZE = getModeratorsSize();

  const GROUP_AVATAR_URL = `${process.env.REACT_APP_IMAGE_URL}${forumDetails.groupImage}`;
  const GROUP_BANNER_URL = `${process.env.REACT_APP_IMAGE_URL}${forumDetails.groupBackgroundImage}`;
  const avatarUrl = forumDetails.avatarLink || '#';
  const currentUrl = 'https://pandlr.com';
  const AVATAR_URL = avatarUrl.replace(currentUrl, '');

  const DESCRIPTION = forumDetails?.description;

  return (
    forumDetails && (
      <S.ForumContainer>
        <S.ForumImagesContainer>
          <S.BannerImage src={GROUP_BANNER_URL} />
          {
            forumDetails.userCredentials?.isModerator && (<ForumModeratosMenu />)
          }
          <S.ForumAvatarContainer>
            <Link to={AVATAR_URL}>
              <S.ForumImage
                src={GROUP_AVATAR_URL}
                alt={forumDetails.name}
              />
            </Link>
          </S.ForumAvatarContainer>
          <S.ForumInfosContainer>
            <S.ForumTitle>
              {forumDetails.name}
            </S.ForumTitle>
          </S.ForumInfosContainer>
        </S.ForumImagesContainer>
        <S.ForumDetailsContainer>
          <S.ForumDescription>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                fontWeight: 'bold',
                mb: '1rem',
                mt: -1,
              }}
            >
              <Box ml={{ xs: 0, sm: 0.5 }}>
                <Box
                  component="span"
                  sx={{
                    display: { xs: 'inline', sm: 'none' },
                  }}
                >
                  {forumDetails.name}

                  {' '}
                  ■
                  {' '}
                </Box>
                {forumDetails.membersCount?.toLocaleString()}
                {' '}
                {forumDetails.membersCount > 1 ? 'membros' : 'membro'}
              </Box>
              <Box sx={{
                mt: MODERATORS_SIZE, mr: { xs: -3, sm: -1 },
              }}
              >
                <AvatarGroup max={7}>
                  {MODERATORS?.slice(0, 7).map((moderator) => (
                    <Tooltip title={`@${moderator.user.username}`} arrow key={`moderator-${moderator.user.id}`}>
                      <Link to={`/user/${moderator.user.username}`} style={{ marginLeft: -7, marginBottom: 1 }}>
                        <Avatar
                          src={`${process.env.REACT_APP_IMAGE_URL}${moderator.user.avatarThumb}`}
                          sx={{
                            width: { xs: 20, sm: 24 },
                            height: { xs: 20, sm: 24 },
                          }}
                        />
                      </Link>
                    </Tooltip>
                  ))}
                </AvatarGroup>
                {MODERATORS_QTY > 6 && (
                  <AvatarGroup max={7}>
                    {MODERATORS?.slice(7, 14).map((moderator) => (
                      <Tooltip title={`@${moderator.user.username}`} arrow key={`moderator-${moderator.user.id}`}>
                        <Link to={`/user/${moderator.user.username}`} style={{ marginLeft: -7, marginBottom: 1 }}>
                          <Avatar
                            src={`${process.env.REACT_APP_IMAGE_URL}${moderator.user.avatarThumb}`}
                            sx={{
                              width: { xs: 20, sm: 24 },
                              height: { xs: 20, sm: 24 },
                            }}
                          />
                        </Link>
                      </Tooltip>
                    ))}
                  </AvatarGroup>
                )}
                {MODERATORS_QTY > 12 && (
                  <AvatarGroup max={7}>
                    {MODERATORS?.slice(14, 21).map((moderator) => (
                      <Tooltip title={`@${moderator.user.username}`} arrow key={`moderator-${moderator.user.id}`}>
                        <Link to={`/user/${moderator.user.username}`} style={{ marginLeft: -7 }}>
                          <Avatar
                            src={`${process.env.REACT_APP_IMAGE_URL}${moderator.user.avatarThumb}`}
                            sx={{
                              width: { xs: 20, sm: 24 },
                              height: { xs: 20, sm: 24 },
                            }}
                          />
                        </Link>
                      </Tooltip>
                    ))}
                  </AvatarGroup>
                )}
              </Box>
            </Box>
            <Box>
              <Box>
                <S.JoinForumButton
                  type="button"
                  onClick={
                    isMember
                      ? handleLeaveGroup : handleJoinGroup
                  }
                >
                  {isMember ? 'Sair do Fórum' : 'Participar do Fórum'}
                </S.JoinForumButton>
              </Box>

              <Box>
                <div dangerouslySetInnerHTML={{ __html: DESCRIPTION?.replace(/\n/g, '<br/>') }} />
                <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                  <Tooltip title="Twitter" arrow>
                    <IconButton aria-label="Twitter" href="https://twitter.com/forumpandlr">
                      <TwitterIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Instagram" arrow>
                    <IconButton aria-label="Instagram" href="https://instagram.com/forumpandlr">
                      <InstagramIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Atendimento" arrow>
                    <IconButton aria-label="Support" href="https://pandlr.com/forum/pan/topic/oficial-atendimento/308">
                      <SupportAgentIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Regras" arrow>
                    <IconButton aria-label="Rules" href="https://pandlr.com/forum/pan/">
                      <GavelIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Agenda" arrow>
                    <IconButton aria-label="Calendar" href="https://pandlr.com/forum/pan/">
                      <CalendarMonthIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Banidos" arrow>
                    <IconButton aria-label="Block" href="https://pandlr.com/forum/pan/">
                      <BlockIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Box>
            </Box>
          </S.ForumDescription>
        </S.ForumDetailsContainer>
      </S.ForumContainer>
    )
  );
}
