import { Header, TopicPost, TopicReplies } from 'components';
import { TopicProvider } from 'contexts';

import * as S from './styles';

export function Topic() {
  return (
    <>
      <Header />
      <S.TopicContainer>
        <TopicProvider>
          <TopicPost />
          <TopicReplies />
        </TopicProvider>
      </S.TopicContainer>
    </>
  );
}
