import { Header, Footer, UserMentions } from 'components';
import { Typography } from '@mui/material';
import * as S from './styles';

export function Mentions() {
  return (
    <>
      <Header />
      <S.MentionsContainer>
        <Typography variant="h4" component="h1" sx={{ mt: { xs: 1, md: -1 }, mb: { xs: -1, md: -2 } }}>
          Menções
        </Typography>
        <S.MentionsInteractionContainer>
          <UserMentions />
        </S.MentionsInteractionContainer>
      </S.MentionsContainer>
      <Footer />
    </>
  );
}
