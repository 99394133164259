export const formatDate = (date, format = 'dd/mm/yyyy') => {
  const newDate = new Date(date);
  let day = newDate.getDate();
  let month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();

  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return `${format.replace('dd', day).replace('mm', month).replace('yyyy', year)} às ${hours}:${minutes}`;
};
