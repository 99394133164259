import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export function ForumTopicsContainer({ children }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      mt={{ xs: '1rem', sm: '2rem', md: '3rem' }}
      bgcolor="background.level2"
      borderRadius="5px"
      boxShadow={(theme) => theme.shadows[1]}
    >
      {children}
    </Box>
  );
}

export function TopicContainer({ children }) {
  return (
    <Box
      display="flex"
      borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
      gap={1}
    >
      {children}
    </Box>
  );
}

export function TopicTitle({ children, to, ...props }) {
  return (
    <Link
      style={{
        textDecoration: 'none',
        color: 'inherit',
      }}
      {...props}
      to={to}
    >
      <Typography
        variant="h5"
        fontSize={{ xs: '1.2rem', sm: '1.4rem', md: '1.6rem' }}
        component="h2"
      >
        {children}
      </Typography>
    </Link>
  );
}

export function TopicContentContainer({ children }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
    >
      {children}
    </Box>
  );
}

export function UserAvatar({ src, alt, ...props }) {
  return (
    <Box
      width={{ xs: '40px', sm: '50px', md: '75px' }}
      height={{ xs: '40px', sm: '50px', md: '75px' }}
      borderRadius="10px"
      {...props}
    >
      <img
        src={src}
        alt={alt}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </Box>
  );
}

export function TopicDate({ children }) {
  return (
    <Typography
      variant="body1"
      fontSize={{ xs: '1rem', sm: '1.2rem', md: '1.4rem' }}
      component="p"
    >
      {children}
    </Typography>
  );
}

export function TopicRepliesCount({ replies }) {
  return (
    <Typography
      variant="body1"
      fontSize={{ xs: '0.5', sm: '0.8', md: '1rem' }}
      component="p"
    >
      {replies}
      {' '}
      {replies > 1 ? 'replies' : 'reply'}
    </Typography>
  );
}

// function that calculates the time difference between the current time and the last reply date
export function calculateTimeDifference(lastReplyDate) {
  const currentDate = new Date();
  const lastReply = new Date(lastReplyDate);
  const difference = currentDate.getTime() - lastReply.getTime();
  const seconds = Math.floor(difference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (seconds < 60) {
    return 'seconds ago';
  }
  if (minutes < 5) {
    return 'seconds ago';
  }
  if (minutes < 60) {
    return `${minutes - 5} ${(minutes - 5) > 1 ? 'minutes' : 'minute'} ago`;
  }
  if (hours < 24) {
    return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago`;
  }
  if (days < 30) {
    return `${days} ${days > 1 ? 'days' : 'day'} ago`;
  }
  if (months < 12) {
    return `${months} ${months > 1 ? 'months' : 'month'} ago`;
  }
  return `${years} ${years > 1 ? 'years' : 'year'} ago`;
}

export function LastReplyDate({ children, ...props }) {
  return (
    <Link
      style={{
        textDecoration: 'none',
        color: 'inherit',
      }}
      {...props}
    >
      <Typography
        variant="h5"
        fontSize={{ xs: '0.5', sm: '0.8', md: '1rem' }}
        component="p"
      >
        {calculateTimeDifference(children)}
      </Typography>
    </Link>
  );
}

export function LastReplyAuthor({ children }) {
  return (
    <Typography
      variant="h5"
      fontSize={{ xs: '0.5', sm: '0.8', md: '1rem' }}
      component="p"
    >
      Last post by @
      {children}
    </Typography>
  );
}

export function TopicDetails({ children }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
    >
      {children}
    </Box>
  );
}
