import React, { useState } from 'react';
import { IconButton, TextField } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { Avatar } from 'components';
import { updateUserInfos, uploadUserAvatar } from 'services/providers/pandlr.provider';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, setPendingUser } from 'redux/slicers';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';
import { selectPendingUser } from '../../redux/slicers/user/userSlice';

export function RegistrationForm() {
  const INITIAL_STATE = {
    name: '',
    username: '',
    avatar: '',
  };

  const [data, setData] = useState(INITIAL_STATE);
  const [avatarUpload, setAvatarUpload] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const pendingUser = useSelector(selectPendingUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevState) => ({
      ...prevState,
      [name]: !value || value.length < 3 || value.length > 20,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
  };

  const uploadAvatar = async () => {
    const formData = new FormData();
    formData.append('image', data.avatar);

    const res = await uploadUserAvatar(formData);
    setAvatarUpload(res.data);

    return res.data;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const image = avatarUpload?.path ? avatarUpload : await uploadAvatar();

    const result = await updateUserInfos({
      ...pendingUser,
      ...data,
      avatar: image.path300,
      avatarThumb: image.path,
      status: 'active',
    });

    if (!result.success) {
      setIsSubmitting(false);
      setErrors((prevState) => ({
        ...prevState,
        username: result.data.message,
      }));

      return;
    }

    dispatch(setUser(result.data));
    dispatch(setPendingUser(null));
    navigate('/forum/pan');

    setIsSubmitting(false);
  };

  return (
    <S.Form onSubmit={onSubmit}>
      <Avatar
        sx={{
          width: '100px',
          height: '100px',
          margin: '0 auto',
          marginBottom: '20px',
        }}
        avatarUrl={data.avatar ? URL.createObjectURL(data.avatar) : ''}
      />
      <S.FormContainer>

        <S.Input>
          <S.LabelText>
            Enter your name
          </S.LabelText>
          <TextField
            variant="outlined"
            fullWidth
            label="Name *"
            name="name"
            value={data.name}
            onChange={handleChange}
            error={!!errors.name}
          />
        </S.Input>

        <S.Input>
          <S.LabelText>
            Enter your @ username
          </S.LabelText>
          <TextField
            variant="outlined"
            fullWidth
            label="Username *"
            name="username"
            value={data.username}
            onChange={handleChange}
            error={!!errors.username}
            helperText={errors.username}
          />
        </S.Input>

        <S.Input>
          <S.LabelUpload>Upload your avatar</S.LabelUpload>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input name="avatar" onChange={handleFileChange} hidden accept="image/*" type="file" />
            <PhotoCamera />
          </IconButton>
        </S.Input>

        <S.FormActions>
          <S.LoadingButton
            disabled={!data.username || !data.avatar || isSubmitting}
            type="submit"
            loading={isSubmitting}
          >
            Continue
          </S.LoadingButton>
        </S.FormActions>

      </S.FormContainer>
    </S.Form>
  );
}
