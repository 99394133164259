import {
  Card,
  Typography,
  Box,
  CardHeader as MuiCardHeader,
  CardContent as MuiCardContent,
  CardActions as MuiCardActions,
  Avatar,
} from '@mui/material';
import { PostContent } from 'common/PostContent';
import { Forum } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export function CardContainer({ children }) {
  return (
    <Card
      sx={{
        backgroundColor: 'background.level2',
        backgroundImage: 'none',
        borderRadius: '0px',
        marginBottom: 0,
        maxHeight: 5000,
      }}
    >
      {children}
    </Card>
  );
}

export function CardHeader({ reply }) {
  const AVATAR_URL = `${process.env.REACT_APP_IMAGE_URL}${reply.replyAuthor?.avatar}`;

  return (
    <MuiCardHeader
      sx={{
        alignItems: 'flex-start',
        paddingBottom: { md: 0 },
      }}
      avatar={(
        <Avatar
          src={AVATAR_URL}
          alt={reply.replyAuthor?.username}
          variant="rounded"
          sx={{
            width: { xs: 45, sm: 50 },
            height: { xs: 45, sm: 50 },
            marginTop: '3px',
          }}
        />
      )}
      title={(
        <Box>
          <Typography
            sx={{
              fontWeight: 'bold',
              mr: 1,
            }}
            component="span"
            variant="body1"
            color="text.primary"
          >
            @
            {reply.replyAuthor?.username}
          </Typography>
          <Typography
            component="span"
            variant="subtitle2"
            color="text.secondary"
            display={{ md: 'none' }}
          >
            {reply.createdAt}
          </Typography>
          <Typography
            sx={{
              fontWeight: 'bold',
              display: { xs: 'none', md: 'inline' },
            }}
            component="span"
            variant="body2"
            color="text.primary"
          >
            postou no tópico -
            {' '}
            {reply.topic?.title}
          </Typography>
        </Box>
      )}
      subheader={(
        <>
          <Typography
            sx={{
              fontWeight: 'bold',
              display: { xs: 'block', md: 'none' },
            }}
            component="span"
            variant="body2"
            color="text.primary"
          >
            {reply.topic?.title}
          </Typography>
          <Typography
            component="span"
            variant="subtitle2"
            color="text.secondary"
            display={{ xs: 'none', md: 'inline' }}
          >
            {reply.createdAt}
          </Typography>
        </>
      )}
    />
  );
}

export function CardContent({ reply, children }) {
  return (
    <MuiCardContent
      sx={{
        paddingTop: 0,
        transform: { xs: 'none', md: 'translateY(-20px)' },
      }}
    >
      <PostContent
        sx={{
          mt: { xs: 2, md: 5 },
          display: 'block',
        }}
        component="span"
        variant="body1"
        color="text.primary"
        id={`post-${reply.id}`}
      >
        {children}
      </PostContent>
    </MuiCardContent>
  );
}

export function CardActions({ children }) {
  return (
    <MuiCardActions
      sx={{
        paddingTop: 0,
        pl: 2,
      }}
    >
      {children}
    </MuiCardActions>
  );
}

export function ViewOnTopic({ reply, ...props }) {
  const REPLY_URL = `/forum/${reply.topic?.group?.slug}/topic/${reply.topic?.slug}/${reply.topic?.id}/page/${reply.currentPage}/#post-${reply.id}`;
  const navigate = useNavigate();

  return (
    <Typography
      sx={{
        fontWeight: 'bold',
        display: 'flex',
        gap: 1,
        cursor: 'pointer',
      }}
      component="span"
      variant="body3"
      color="text.primary"
      {...props}
      onClick={() => navigate(REPLY_URL)}
    >
      <Forum fontSize="small" />
      Ver no tópico
    </Typography>
  );
}
