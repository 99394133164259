import { useMediaQuery } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setGifOptions, setQuoteReply, setEditorId, setSelectedImage,
} from 'redux/slicers';

export function TextEditorLight({ value, setValue }) {
  const {
    gifOptions, quoteReply, fastReply, user, gifPocket, selectedImage,
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { isLogged } = user;

  const editorRef = useRef(null);

  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const contentStyle = `p { max-width: ${isSm ? '87%' : '95%'}; }`;

  function transformValue(checkValue, gifPocketFiles) {
    let transformedValue = checkValue;
    gifPocketFiles.forEach((gifPocketFile) => {
      const regex = new RegExp(gifPocketFile.shortcut, 'g');
      transformedValue = transformedValue.replace(regex, `<img alt="Gif Pocket" src="${gifPocketFile.imageUrl}" />`);
    });
    return transformedValue;
  }

  const handleUpdate = (newValue) => {
    const valueWithShortcut = transformValue(newValue, gifPocket);
    setValue(valueWithShortcut);
  };

  useEffect(() => {
    if (selectedImage) {
      setValue(`${value} <img src="${selectedImage}" />`);
      dispatch(setSelectedImage(''));
    }
  }, [selectedImage]);

  useEffect(() => {
    if (gifOptions.image) {
      setValue(`${value} <img src="${gifOptions.image}" />`);
      dispatch(setGifOptions({ image: '' }));
    }
  }, [gifOptions.image]);

  useEffect(() => {
    if (quoteReply.content) {
      setValue(`${value} <blockquote><strong>@${quoteReply.username}</strong> <br /> ${quoteReply.content}</blockquote> <br />`);
      dispatch(setQuoteReply({ content: '' }));
    }
  }, [quoteReply.content]);

  const fastReplyFunction = (editor) => {
    if (fastReply) {
      editor.on('keydown', (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          if (!isLogged) return document.getElementById('login-pandlr').children[0].click();
          return document.getElementById('submit').click();
        }
        return null;
      });
    }
  };

  const onInit = (_e, editor) => {
    editorRef.current = editor;
    dispatch(setEditorId(editor.id));
    fastReplyFunction(editor);
  };

  useEffect(() => {
    if (editorRef.current) {
      const editor = editorRef.current;
      if (fastReply) {
        fastReplyFunction(editor);
      } else {
        editor.off('keydown');
      }
    }
  }, [editorRef, fastReply]);

  const isDesktop = window.matchMedia('(min-width: 800px)').matches;
  const isTablet = window.matchMedia('(min-width: 600px) and (max-width: 800px)').matches;

  const toolbar = 'image bold italic underline strikethrough alignleft aligncenter alignright alignjustify fontsizeselect forecolor backcolor removeformat undo redo code';
  const toolbarTablet = 'image bold italic underline alignleft aligncenter alignright';
  const toolbarMobile = 'image';

  return (
    <Editor
      onInit={(e, editor) => onInit(e, editor)}
      plugins="code autoresize image"
      toolbar={(isDesktop && toolbar) || (isTablet ? toolbarTablet : toolbarMobile)}
      init={{
        selector: 'textarea',
        autoresize_bottom_margin: 0,
        toolbar_location: 'bottom',
        branding: false,
        menubar: false,
        statusbar: false,
        toolbar_mode: 'sliding',
        height: 50,
        max_height: 400,
        force_br_newlines: true,
        content_style: contentStyle,
      }}
      initialValue=""
      value={value}
      onEditorChange={handleUpdate}
    />
  );
}
