import Box from '@mui/material/Box';

export function Footer() {
  return (
    <Box textAlign="center" marginTop={2} marginBottom={2}>
      Copyright © Pandlr 2015 - 2023.
      <Box>
        Versão: 3.0.3.
      </Box>
    </Box>
  );
}
