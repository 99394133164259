import { Box, Button as MUIButton, Typography } from '@mui/material';
import styled from '@emotion/styled';

export function ForumContainer({ children }) {
  return (
    <Box
      bgcolor="background.level2"
      borderRadius="5px"
      boxShadow={(theme) => theme.shadows[1]}
      sx={{
        mt: { xs: '1rem', md: '-2rem' },
      }}
    >
      {children}
    </Box>
  );
}

export function ForumImagesContainer({ children }) {
  return (
    <Box
      position="relative"
      width="100%"
      height={{ xs: '200px', sm: '250px', md: '300px' }}
      borderRadius="10px 10px 0 0"
    >
      {children}
    </Box>
  );
}

export const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export function ForumAvatarContainer({ children }) {
  return (
    <Box
      width={{ xs: '100px', sm: '150px', md: '200px' }}
      height={{ xs: '100px', sm: '150px', md: '200px' }}
      display="flex"
      position="absolute"
      bottom={{ xs: '31%', sm: '-12%', md: '-12%' }}
      left="2%"
    >
      {children}
    </Box>
  );
}

export const ForumImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid #2d3436;
  position: absolute;
  bottom: -60%;
  left: 2%; 
  transition: all 0.3s ease-in-out; 
`;

export function ForumInfosContainer({ children }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={{ xs: '0.5rem', sm: '0.8rem', md: '1rem' }}
      position="absolute"
      bottom={{ xs: 'calc(-2%)' }}
      left={{ xs: 'calc(2% + 100px + 1rem)', sm: 'calc(2% + 150px + 1rem)', md: 'calc(2% + 200px + 1rem)' }}
    >
      {children}
    </Box>
  );
}

export function ForumTitle({ children }) {
  return (
    <Typography
      variant="h3"
      component="h1"
      fontSize={{ xs: '1.8rem', sm: '2.4rem', md: '3.2rem' }}
      fontFamily={(theme) => theme.typography.fontFamily}
      sx={{
        textShadow: '1px 1px 4px #000',
        color: '#ecf0f1',
        display: { xs: 'none', sm: 'inline', md: 'inline' },
      }}
    >
      {children}
    </Typography>
  );
}

export function MembersContainer({ children }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
    >
      {children}
    </Box>
  );
}

export function JoinForumButton({ children, ...props }) {
  return (
    <Typography
      variant="body1"
      component="span"
      display="block"
      sx={{
        mt: -5,
        float: 'right',
        mr: { xs: -2.7, sm: -0.8 },
      }}
    >
      <MUIButton
        variant="outlined"
        color="background"
        size="small"
        fontSize={{ xs: '1rem', sm: '1.2rem', md: '1.4rem' }}
        sx={{
          fontWeight: 'bold',
          border: '1.7px solid',
          mt: 0,
        }}
        {...props}
      >
        {children}
      </MUIButton>
    </Typography>
  );
}

export function ForumDescription({ children }) {
  return (
    <Typography
      variant="body1"
      fontSize={{ xs: '1rem' }}
      component="div"
    >
      {children}
    </Typography>
  );
}

export function ForumDetailsContainer({ children }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      minHeight={{ xs: '200px', md: '160px', lg: '170px' }}
      gap={1}
      pr={{ xs: '2rem', sm: '1rem', md: '1rem' }}
      mt={{ xs: '1rem', sm: '1rem', md: '1rem' }}
      ml={{ xs: '1rem', sm: 'calc(2% + 150px + 1rem)', md: 'calc(2% + 200px + 1rem)' }}
    >
      {children}
    </Box>
  );
}
