import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState({ editorId: null })('textEditor');

export const textEditorSlice = createSlice({
  name: 'textEditor',
  initialState,
  reducers: {
    setEditorId: (state, action) => {
      state.editorId = action.payload;
    },
  },
});

export const { setEditorId } = textEditorSlice.actions;
