import { useState } from 'react';
import { paperProps } from 'utils/paperProps';
import {
  Menu, MenuItem, ListItemIcon, IconButton,
} from '@mui/material';
import {
  forumTopicDelete, closeTopic, openTopic, pinTopicByTopicId, unpinTopicByTopicId,
} from 'services/providers/pandlr.provider';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { Reply, MoreVert } from '@mui/icons-material';
import { setSnackbar } from 'redux/slicers';
import FlagIcon from '@mui/icons-material/Flag';
import WarningIcon from '@mui/icons-material/Warning';
import LaunchIcon from '@mui/icons-material/Launch';
import PushPinIcon from '@mui/icons-material/PushPin';
import { EditTopicModal } from 'components/Modals';

export function TopicMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { topicDetails } = useSelector((state) => state);
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleForumReplyDelete = async () => {
    if (!topicDetails?.id) return null;
    handleClose();
    const result = await forumTopicDelete(
      { topicId: topicDetails.id, groupId: topicDetails.groupId },
    );
    if (result.success) return dispatch(setSnackbar({ message: 'Topic deleted', status: 'success' }));
    return dispatch(setSnackbar({ message: 'Error deleting topic', status: 'error' }));
  };

  const handleOpenTopic = async () => {
    if (!topicDetails?.id) return null;
    handleClose();
    const result = await openTopic(
      { topicId: topicDetails.id, groupId: topicDetails.groupId },
    );
    if (result.success) return dispatch(setSnackbar({ message: 'Tópico liberado para comentários', status: 'success' }));
    return dispatch(setSnackbar({ message: 'Erro ao liberar tópico', status: 'error' }));
  };

  const handleCloseTopic = async () => {
    if (!topicDetails?.id) return null;
    handleClose();
    const result = await closeTopic(
      { topicId: topicDetails.id, groupId: topicDetails.groupId },
    );
    if (result.success) return dispatch(setSnackbar({ message: 'Topic fechado para comentários', status: 'success' }));
    return dispatch(setSnackbar({ message: 'Error ao fechar tópico', status: 'error' }));
  };

  const handlePinTopic = async () => {
    if (!topicDetails?.id) return null;
    handleClose();
    const result = await pinTopicByTopicId(
      { topicId: topicDetails.id, groupId: topicDetails.groupId },
    );
    if (result.success) return dispatch(setSnackbar({ message: 'Tópico destacado', status: 'success' }));
    return dispatch(setSnackbar({ message: 'Erro ao destacar tópico', status: 'error' }));
  };

  const handleUnpinTopic = async () => {
    if (!topicDetails?.id) return null;
    handleClose();
    const result = await unpinTopicByTopicId(
      { topicId: topicDetails.id, groupId: topicDetails.groupId },
    );
    if (result.success) return dispatch(setSnackbar({ message: 'Destaque removido', status: 'success' }));
    return dispatch(setSnackbar({ message: 'Erro ao remover destaque do tópico', status: 'error' }));
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={paperProps}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          sx={{ display: { xs: 'flex', sm: 'none' } }}
        >
          <ListItemIcon>
            <Reply sx={{
              width: 20,
            }}
            />
          </ListItemIcon>
          @
          {topicDetails.topicAuthor?.username}
        </MenuItem>
        <MenuItem
          onClick={() => {
            alert('Option not available yet');
            handleClose();
          }}
        >
          <ListItemIcon>
            <FlagIcon sx={{
              width: 20,
            }}
            />
          </ListItemIcon>
          Denunciar
        </MenuItem>
        {topicDetails.userCredentials?.isModerator
          && (
            <div>
              <EditTopicModal />
              <MenuItem onClick={handleForumReplyDelete}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                Excluir
              </MenuItem>
              <MenuItem onClick={handleCloseTopic}>
                <ListItemIcon>
                  <WarningIcon />
                </ListItemIcon>
                Fechar
              </MenuItem>
              <MenuItem onClick={handleOpenTopic}>
                <ListItemIcon>
                  <LaunchIcon />
                </ListItemIcon>
                Abrir
              </MenuItem>
              <MenuItem onClick={handlePinTopic}>
                <ListItemIcon>
                  <PushPinIcon />
                </ListItemIcon>
                Destacar
              </MenuItem>
              <MenuItem onClick={handleUnpinTopic}>
                <ListItemIcon>
                  <PushPinIcon />
                </ListItemIcon>
                Remover destaque
              </MenuItem>
            </div>
          )}
      </Menu>
    </>
  );
}
