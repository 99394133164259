import React, { useEffect } from 'react';
import { Footer, Header } from 'components';
import { UpdateUserForm, WidgetPreferences } from 'components/Forms';
import * as S from './styles';

export function Settings() {
  useEffect(() => {
    document.title = 'Settings';
  }, []);

  return (
    <>
      <Header />
      <S.SettingsContainer>
        <UpdateUserForm />
        <WidgetPreferences />
      </S.SettingsContainer>
      <Footer />
    </>
  );
}
