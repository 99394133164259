import {
  useEffect, useState, useContext, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTopicDetails } from 'redux/slicers';
import { getTopicDetails } from 'services/providers/pandlr.provider';
import parse from 'html-react-parser';
import * as DOMPurify from 'dompurify';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Typography,
  CardHeader,
  CardContent,
} from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import { GifOptions, TopicMenu } from 'components/Menus';
import { TopicContext } from 'contexts';
import {
  useLocation, useNavigate, useParams,
} from 'react-router-dom';
import Embed from 'components/Embed/Embed';
import { CardContainer } from 'common/CardContainer';
import { TopicSkeleton } from './TopicSkeleton';
import { TopicPagination } from './TopicPagination';
import { PostContent } from '../../common/PostContent';
import { MenuUserAvatar } from '../Menus/UserMenuAvatar';

export function TopicPost() {
  const { setTopic, topic } = useContext(TopicContext);
  const [showGifOptions, setShowGifOptions] = useState({
    show: false, image: '', UUID: '', positions: { top: '', left: '' },
  });
  const { user, topicDetails } = useSelector((state) => state);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { id, pageNumber = '1' } = useParams();

  const dispatch = useDispatch();

  const handlePageChange = (page) => {
    if (!pathname.includes('/page/')) {
      return navigate(`${pathname}/page/${page}`);
    }
    return navigate(`${pathname.replace(`page/${pageNumber}`, `page/${page}`)}`);
  };

  const handleClick = (UUID) => {
    const image = document.getElementById(UUID);
    const rect = image.getBoundingClientRect();
    const positions = {
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    };
    setShowGifOptions({
      show: true, image: image.src, UUID, positions, element: image,
    });
  };

  const cleanHtmlTopicContent = DOMPurify.sanitize(topic.content);

  const renderEmbed = useCallback((url) => <Embed url={url} />, []);

  const topicContent = parse(cleanHtmlTopicContent, {
    replace: (domNode) => {
      if (domNode.type === 'text' && /https?:\/\//.test(domNode.data)) {
        return renderEmbed(domNode.data);
      }
      if (domNode.name === 'img' && domNode.attribs.src) {
        const UUID = uuidv4();
        domNode.attribs.id = `${UUID}`;
        domNode.attribs.style = 'cursor: pointer;';
        domNode.attribs.onClick = () => handleClick(UUID);
      }
      return domNode;
    },
  });

  useEffect(() => {
    const fetchTopicDetails = async () => {
      const result = await getTopicDetails(id, user.user?.id);
      if (result.code === 404) {
        navigate('/404');
      }
      document.title = `${result.data.title} - Pandlr`;
      setTopic(result.data); dispatch(setTopicDetails(result.data));
    };
    fetchTopicDetails();
    return () => {
      setTopic({});
    };
  }, [id]);

  return (
    <>
      <GifOptions
        showGifOptions={showGifOptions}
        setShowGifOptions={setShowGifOptions}
      />
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        mt="0.5rem"
        justifyContent="space-between"
      >
        <Typography
          variant="h5"
          component="h6"
          color="text.primary"
          fontSize={{ xs: '1rem', sm: '1.5rem' }}
        >
          {topicDetails?.title}
        </Typography>
        <Box
          sx={{
            minWidth: '160px',
            alignSelf: 'flex-end',
            mb: { xs: '-1rem', sm: '0' },
          }}
        >
          <TopicPagination onSelectPage={handlePageChange} />
        </Box>
      </Box>
      {
        pageNumber === '1' && (
          <Box
            display="flex"
            flexDirection="column"
            mt="1rem"
            bgcolor="background.level2"
            borderRadius={1}
            overflow="hidden"
            boxShadow={(theme) => theme.shadows[1]}
          >
            <CardContainer>
              {!topic
                ? <TopicSkeleton />
                : (
                  <>
                    <CardHeader
                      sx={{
                        alignItems: 'flex-start',
                        paddingBottom: { md: 0 },
                      }}
                      avatar={(
                        <MenuUserAvatar
                          user={topic.topicAuthor}
                          src={`${process.env.REACT_APP_IMAGE_URL}${topic.topicAuthor?.avatar}`}
                          alt={topic.topicAuthor?.username}
                          variant="rounded"
                          sx={{
                            width: { xs: 45, sm: 50, md: 75 },
                            height: { xs: 45, sm: 50, md: 75 },
                            marginTop: '3px',
                            cursor: 'pointer',
                          }}
                        />
                      )}
                      action={(
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                            cursor: 'pointer',
                          }}
                        >
                          <Box
                            sx={{
                              display: { xs: 'none', sm: 'flex' },
                              alignItems: 'center',
                              gap: '5px',
                            }}
                          >
                            <ReplyIcon sx={{
                              width: 20,
                            }}
                            />
                            <Typography
                              sx={{ display: { xs: 'block', sm: 'inline' } }}
                              component="span"
                              variant="body3"
                              color="text.secondary"
                            >
                              @
                              {topic.topicAuthor?.username}
                            </Typography>
                          </Box>
                          <TopicMenu />
                        </Box>
                      )}
                      title={(
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            mr: 1,
                            cursor: 'pointer',
                          }}
                          component="span"
                          variant="body1"
                          color="text.primary"
                          onClick={() => navigate(`/user/${topic.topicAuthor?.username}`)}
                        >
                          {topic.topicAuthor?.name}
                        </Typography>
                      )}
                      subheader={(
                        <Typography
                          sx={{ display: { xs: 'block', md: 'inline' } }}
                          component="span"
                          variant="body3"
                          color="text.secondary"
                        >
                          {topic.createdAt}
                        </Typography>
                      )}
                    />
                    <CardContent
                      sx={{
                        paddingTop: 0,
                        transform: { xs: 'none', md: 'translateY(-20px)' },
                      }}
                    >
                      <PostContent
                        sx={{
                          ml: { xs: 0, sm: 0, md: 12 },
                          mt: 0,
                          display: 'block',
                        }}
                        component="span"
                        variant="body1"
                        color="text.primary"
                      >
                        {topicContent}
                      </PostContent>
                    </CardContent>
                  </>
                )}
            </CardContainer>
          </Box>
        )
      }
    </>
  );
}
