import { useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { createNewReply } from 'services/providers/pandlr.provider';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/slicers';
import {
  TextEditorLight, TextEditorDark, GifPocketModal,
} from 'components';
import { Box, Fab, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { v4 as uuidv4 } from 'uuid';
import { socket } from 'services/io/ioConnection';
import { TopicContext } from 'contexts';

export function TopicNewReply({ setReplies }) {
  const { id: topicId } = useParams();
  const { topic, topic: { groupId } } = useContext(TopicContext);
  const { darkMode, user } = useSelector((state) => state);
  const [value, setValue] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLogged } = user;

  const { isClosed } = topic;

  const isModerator = topic?.userCredentials?.isModerator;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isLogged) return null;

    const newReply = {
      content: value,
      topicId,
      forumId: groupId,
      userInfo: user.user,
    };

    const mockReply = {
      id: uuidv4(),
      replyAuthor: {
        avatarThumb: user.user.avatarThumb,
        avatar: user.user.avatar,
        username: user.user.username,
        name: user.user.name,
        id: user.user.id,
      },
      content: value,
      createdAt: 'seconds ago',
    };
    setValue('');

    setReplies((prev) => [...prev, mockReply]);

    const response = await createNewReply(newReply);

    if (response.success) {
      return socket.emit('newReply', { room: response.data.topicId, reply: { ...mockReply, id: response.data.id } });
    }

    setReplies((prev) => prev.filter((reply) => reply.id !== mockReply.id));
    setValue(mockReply.content);
    return dispatch(setSnackbar({ status: 'error', message: response.data.message }));
  };

  return (
    isClosed && !isModerator
      ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <p>Este tópico foi fechado pela moderação</p>
        </Box>
      )
      : (
        <>
          {isClosed && isModerator && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <p>Apenas moderadores podem comentar neste tópico</p>
            </Box>
          )}
          <Box
            sx={{
              position: 'sticky',
              width: '100%',
            }}
            bottom={0}
          >
            <form onSubmit={handleSubmit} id="reply-form">

              {darkMode.active
                ? <TextEditorDark value={value} setValue={setValue} />
                : <TextEditorLight value={value} setValue={setValue} />}

              {isLogged
                ? (
                  <Fab
                    color="primary"
                    size="small"
                    sx={{
                      position: 'absolute',
                      right: 15,
                      top: 10,
                    }}
                    id="submit"
                    type="submit"
                  >
                    <SendIcon />
                  </Fab>
                )
                : (
                  <Fab
                    color="primary"
                    size="small"
                    sx={{
                      position: 'absolute',
                      right: 15,
                      top: 10,
                    }}
                    id="login"
                    type="button"
                    onClick={() => document.getElementById('login-pandlr').children[0].click()}
                  >
                    <SendIcon />
                  </Fab>
                )}

              <GifPocketModal />

              <Button
                sx={{
                  position: 'absolute',
                  right: 15,
                  bottom: 10,
                  backgroundColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                  color: 'primary.contrastText',
                  fontWeight: 'bold',
                  zIndex: 1,
                }}
                size="small"
                onClick={() => { navigate('/forum/pan'); }}
              >
                Voltar para o fórum
              </Button>

            </form>
          </Box>

        </>
      )
  );
}
