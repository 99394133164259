import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getForumBans, unbanForumUser } from 'services/providers/pandlr.provider';
import {
  Box, Button, Divider, List, Typography,
} from '@mui/material';
import ForumBanCard from 'components/Cards/ForumBanCard';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { setSnackbar } from 'redux/slicers';

export function ForumBansList() {
  const [bans, setBans] = React.useState([]);
  const [helperText, setHelperText] = useState('');
  const { forumId } = useParams();
  const [pagination, setPagination] = useState({});

  const HAS_MORE = !!pagination.totalPages === 0 || pagination.currentPage < pagination.totalPages;

  const dispatch = useDispatch();

  const fetchForumBans = async (page) => {
    const res = await getForumBans(forumId, page);
    if (res.success) {
      const { items, ...paginationOptions } = res.data;
      setBans((prevBans) => {
        if (prevBans.length > 70) {
          return [...prevBans.slice(30), ...items];
        }
        return [...prevBans, ...items];
      });
      setPagination(paginationOptions);
    }
    setHelperText(res.data.message);
  };

  const handleUnban = async (ban) => {
    const res = await unbanForumUser({
      groupId: forumId,
      userId: ban.userId,
    });
    if (res.success) {
      dispatch(setSnackbar({
        message: res.data.message,
        status: 'success',
      }));
      return fetchForumBans();
    }
    return dispatch(setSnackbar({
      message: res.data.message,
      status: 'error',
    }));
  };

  useEffect(() => {
    fetchForumBans(1);

    return () => {
      setBans([]);
      setPagination({});
    };
  }, []);

  return (
    <>
      <Typography variant="h5" sx={{ m: 2 }}>
        Banidos
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        mt="1rem"
        bgcolor="background.level2"
        borderRadius={1}
        overflow="hidden"
        boxShadow={(theme) => theme.shadows[1]}
      >
        <List>
          {
            bans.length > 0 ? (
              <InfiniteScroll
                dataLength={bans.length}
                pageStart={0}
                next={() => fetchForumBans(pagination.currentPage + 1)}
                useWindow
                hasMore={HAS_MORE}
              >
                {bans.map((ban) => (
                  <Box key={uuidv4()}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      height="100%"
                      p={2}
                    >
                      <ForumBanCard ban={ban} />
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ marginTop: 2 }}
                        onClick={() => handleUnban(ban)}
                      >
                        Desbanir
                      </Button>
                    </Box>
                    <Divider variant="middle" />
                  </Box>
                ))}
              </InfiniteScroll>
            ) : (
              <Typography
                variant="h4"
                color="text.primary"
                component="span"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {helperText || 'Não há usuários banidos neste fórum'}
              </Typography>
            )
          }
        </List>
      </Box>
    </>
  );
}
