import { useEffect } from 'react';
import { serverCheck } from 'services/providers/pandlr.provider';

export function ServerCheck() {
  useEffect(() => {
    serverCheck().then((response) => {
      console.log(response);
    });
  }, []);

  return (
    <div>ServerCheck</div>
  );
}
