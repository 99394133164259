import { Divider, Box } from '@mui/material';
import ReplySkeleton from 'components/Reply/ReplySkeleton';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { getMentionsByUserId } from 'services/providers/pandlr.provider';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UserReplyCard } from 'components/UserProfile/UserReplies/UserReplyCard';
import * as S from 'components/UserProfile/UserReplies/styles';
import { setSnackbar } from 'redux/slicers';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import IconButton from '@mui/material/IconButton';

export function UserMentions() {
  const dispatch = useDispatch();
  const [userMentions, setUserMentions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { username } = useSelector((state) => state.user.user);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
  });

  const fetchUserReplies = async (page) => {
    if (!username) return;
    setIsLoading(true);
    const res = await getMentionsByUserId(username, page);
    if (res.success) {
      const { items, ...paginationOptions } = res.data;
      setUserMentions((prevReplies) => {
        if (prevReplies.length > 70) {
          return [...prevReplies.slice(30), ...items];
        }
        return [...prevReplies, ...items];
      });
      setPagination(paginationOptions);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setUserMentions([]);
    fetchUserReplies(1);

    return () => {
      setUserMentions([]);
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    };
  }, [username]);

  const HAS_MORE = !!pagination.totalPages === 0 || pagination.currentPage < pagination.totalPages;

  const renderSkeletons = () => Array(5).fill(0).map(() => (
    <div key={`reply-skeleton-${uuidv4()}`}>
      <ReplySkeleton />
      <Divider variant="middle" />
    </div>
  ));

  return (
    <S.UserRepliesContainer>
      {isLoading && renderSkeletons()}
      <Box sx={{
        display: 'flex', alignSelf: 'flex-end', mt: { xs: -5, md: -7 },
      }}
      >
        <IconButton
          onClick={async () => {
            await fetchUserReplies(1);
            dispatch(setSnackbar({ message: 'Menções atualizadas!', severity: 'success' }));
          }}
          sx={{ color: 'text.secondary', mr: 1 }}
        >
          <AutorenewIcon />
        </IconButton>
      </Box>
      <InfiniteScroll
        dataLength={userMentions.length}
        pageStart={0}
        next={() => fetchUserReplies(pagination.currentPage + 1)}
        useWindow
        hasMore={HAS_MORE}
        loader={userMentions.length > 0 && renderSkeletons()}
      >
        {userMentions?.map((reply) => (
          <UserReplyCard key={`reply-${reply.id}`} reply={reply} />
        ))}
      </InfiniteScroll>
    </S.UserRepliesContainer>
  );
}
