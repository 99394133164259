import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar, setTopicDetails } from 'redux/slicers';
import { useNavigate } from 'react-router-dom';
import { editTopic } from 'services/providers/pandlr.provider';
import { TextEditorLightFixed, TextEditorDarkFixed } from 'components';
import { ListItemIcon, MenuItem, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { Edit } from '@mui/icons-material';
import * as S from './styles';

export function EditTopicModal() {
  const [open, setOpen] = useState(false);
  const { darkMode, topicDetails, forumDetails } = useSelector((state) => state);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValue] = useState(topicDetails.content);
  const [title, setTitle] = useState(topicDetails.title);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const updatedTopic = {
      title: e.target.title.value,
      content: value,
      topicId: topicDetails.id,
      forumId: topicDetails.groupId,
    };
    const response = await editTopic(updatedTopic);
    if (response.success) {
      dispatch(setTopicDetails(response.data));
      dispatch(setSnackbar({ status: 'success', message: 'Tópico editado com sucesso!' }));
      handleClose();
      return navigate(`/forum/${forumDetails?.slug}/topic/${response.data.slug}/${response.data.id}`);
    }
    dispatch(setSnackbar({ status: 'error', message: response.data.message }));
    return setLoading(false);
  };

  useEffect(() => {
    setValue(topicDetails.content);
    setTitle(topicDetails.title);
  }, [topicDetails, open]);

  return (
    <>
      <MenuItem onClick={handleOpen}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        Editar
      </MenuItem>
      <S.Modal
        open={open}
        handleClose={handleClose}
        modalName={S.MODAL_STYLES.EDIT_TOPIC_MODAL}
        title="Editar tópico"
      >
        <form onSubmit={handleSubmit}>
          <TextField
            id="title"
            label="Topic title"
            variant="outlined"
            size="small"
            sx={{ mb: 2 }}
            autoFocus
            fullWidth
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {
            darkMode.active
              ? <TextEditorDarkFixed setValue={setValue} value={value} />
              : <TextEditorLightFixed setValue={setValue} value={value} />
          }
          <LoadingButton
            loading={loading}
            variant="contained"
            size="large"
            type="submit"
            sx={{ mt: 1 }}
            endIcon={<SendIcon />}
            loadingPosition="end"
          >
            Salvar
          </LoadingButton>
        </form>
      </S.Modal>
    </>
  );
}
