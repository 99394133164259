import * as yup from 'yup';

export const validateSignUpForm = yup.object({
  name: yup
    .string('Enter your name')
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your name'),
  username: yup
    .string('Enter your username')
    .trim()
    .min(2, 'Please enter a valid username')
    .max(50, 'Please enter a valid username')
    .required('Please specify your username'),
  email: yup
    .string('Enter your email')
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  password: yup
    .string()
    .required('Please specify your password')
    .min(6, 'The password should have at minimum length of 6 characters'),
  confirmPassword: yup
    .string()
    .required('Please confirm your password')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  phoneNumber: yup
    .string('Enter your phone number')
    .trim()
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number')
    .required('Please specify your phone number'),
});

export const validateLoginForm = yup.object({
  email: yup
    .string('Enter your email')
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  password: yup
    .string()
    .required('Please specify your password')
    .min(6, 'The password should have at minimum length of 6 characters'),
});

export const ValidateAddGifForm = yup.object({
  imageUrl: yup.string()
    .matches(/(https?:\/\/.*\.(?:png|jpg|jpeg|gif))/i, 'URL inválida')
    .required('Campo obrigatório'),
  shortcut: yup.string()
    .min(3, 'Mínimo de 3 caracteres')
    .max(20, 'Máximo de 20 caracteres')
    .required('Campo obrigatório'),
});
