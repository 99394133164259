import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState({
  currentPage: 1,
  totalPages: 0,
  totalItems: 1,
});

export const topicPaginationSlice = createSlice({
  name: 'topicPagination',
  initialState,
  reducers: {
    setTopicPagination: (state, { payload }) => {
      state.currentPage = payload.currentPage;
      state.totalPages = payload.totalPages || state.totalPages;
    },
  },
});

export const { setTopicPagination } = topicPaginationSlice.actions;
