import { useState } from 'react';
import { Avatar } from 'components';
import { useSelector } from 'react-redux';
import { Logout, Settings } from '@mui/icons-material';
import { selectUser } from 'redux/slicers/user/userSlice';
import { useNavigate } from 'react-router-dom';
import { paperProps } from 'utils/paperProps';
import {
  Menu, MenuItem, ListItemIcon,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

export function LoggedMenu() {
  const user = useSelector(selectUser);
  const { username } = user;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    navigate('/logout');
  };

  const handleSettings = async () => {
    navigate('/settings');
  };

  return (
    <>
      <Avatar
        onClick={handleClick}
        avatarUrl={`${process.env.REACT_APP_IMAGE_URL}${user.avatar}`}
        sx={{ cursor: 'pointer' }}
      />
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={paperProps}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => navigate(`/user/${username}`)}>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          Meu Perfil
        </MenuItem>
        <MenuItem onClick={handleSettings}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Configurações
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
