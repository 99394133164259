import {
  Skeleton, ListItem, ListItemAvatar, ListItemText,
} from '@mui/material';

export function TopicSkeleton() {
  return (
    <ListItem
      sx={{
        gap: 2,
      }}
    >
      <ListItemAvatar>
        <Skeleton variant="rounded" width={75} height={75} />
      </ListItemAvatar>
      <ListItemText
        primary={(
          <Skeleton variant="text" width="30%" />
        )}
        secondary={(
          <Skeleton variant="text" width="50%" />
        )}
      />
    </ListItem>
  );
}
