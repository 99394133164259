import { Box, Typography, Skeleton } from '@mui/material';
import styled from '@emotion/styled';

export function UserContainer({ children }) {
  return (
    <Box
      bgcolor="background.level2"
      borderRadius="5px"
      boxShadow={(theme) => theme.shadows[1]}
      sx={{
        mt: { xs: '1rem', md: '-2rem' },
      }}
    >
      {children}
    </Box>
  );
}

export function UserImagesContainer({ children }) {
  return (
    <Box
      position="relative"
      width="100%"
      height={{ xs: '200px', sm: '250px', md: '300px' }}
    >
      {children}
    </Box>
  );
}

export const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export function UserAvatarContainer({ children }) {
  return (
    <Box
      width={{ xs: '100px', sm: '150px', md: '170px' }}
      height={{ xs: '100px', sm: '150px', md: '170px' }}
      display="flex"
      position="absolute"
      bottom={{ xs: '31%', sm: '-12%', md: '-12%' }}
      left="2%"
    >
      {children}
    </Box>
  );
}

export const UserImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid #2d3436;
  position: absolute;
  bottom: -60%;
  left: 2%; 
  transition: all 0.3s ease-in-out; 
`;

export function UserInfosContainer({ children }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={{ xs: '0.5rem', sm: '0.8rem', md: '1rem' }}
      position="absolute"
      bottom="0"
      left={{ xs: 'calc(2% + 100px + 1rem)', sm: 'calc(2% + 150px + 1rem)', md: 'calc(2% + 170px + 1rem)' }}
    >
      {children}
    </Box>
  );
}

export function UserName({ children }) {
  return (
    <Typography
      variant="h3"
      component="h1"
      fontSize={{ xs: '1.8rem', md: '2.0rem' }}
      fontFamily={(theme) => theme.typography.fontFamily}
      sx={{
        textShadow: '1px 1px 4px #000',
        color: '#ecf0f1',
        display: { xs: 'none', sm: 'inline', md: 'inline' },
      }}
    >
      {children}
    </Typography>
  );
}

export function UserDetailsContainer({ children }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight={{ xs: '100px', sm: '150px' }}
      gap={1}
      pr={{ xs: '2rem', sm: '1rem', md: '1rem' }}
      mt={0.5}
      ml={{ xs: '0.6rem', sm: 'calc(2% + 150px + 1rem)', md: 'calc(2% + 170px + 1rem)' }}
    >
      {children}
    </Box>
  );
}

export function UserNameMobile({ children }) {
  return (
    <Typography
      variant="h3"
      component="h1"
      fontSize={{ xs: '1.8rem', sm: '2.4rem', md: '3.2rem' }}
      fontFamily={(theme) => theme.typography.fontFamily}
      sx={{
        fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
        display: { xs: 'block', sm: 'none' },
      }}
    >
      {children}
    </Typography>
  );
}

export function UserUsername({ children, ...props }) {
  return (
    <Typography
      variant="h4"
      component="h2"
      fontSize={{ xs: '1rem', sm: '1.3rem', md: '1.3rem' }}
      {...props}
    >
      {children}
    </Typography>
  );
}

export function ProfileSkeleton() {
  return (
    <Box
      bgcolor="background.level2"
      borderRadius="5px"
      boxShadow={(theme) => theme.shadows[1]}
      sx={{
        mt: { xs: '1rem', md: '-2rem' },
      }}
    >
      <UserContainer>
        <UserImagesContainer>
          <Skeleton variant="rectangular" animation="wave" width="100%" height="100%" />
          <UserAvatarContainer>
            <Skeleton
              variant="rounded"
              animation="wave"
              width="100%"
              height="100%"
              sx={{
                position: 'absolute',
                bottom: '-55%',
                left: '2%',
              }}
            />
          </UserAvatarContainer>
          <UserInfosContainer>
            <Skeleton variant="text" animation="wave" width="10%" height="10px" />
          </UserInfosContainer>
        </UserImagesContainer>
        <UserDetailsContainer>
          <UserNameMobile>
            <Skeleton variant="text" animation="wave" width="50%" />
          </UserNameMobile>
          <UserUsername>
            <Skeleton variant="text" animation="wave" width="50%" />
          </UserUsername>
        </UserDetailsContainer>
      </UserContainer>
    </Box>
  );
}
