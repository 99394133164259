import {
  TextField as MUITextField, Select as MUISelect,
} from '@mui/material';

export function ControlledTextField({
  formik, name, label, ...rest
}) {
  return (
    <MUITextField
      label={label}
      name={name}
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      autoComplete="on"
      onBlur={formik.handleBlur}
      {...rest}
    />
  );
}

export function ControlledSelect({
  formik, name, label, children, ...rest
}) {
  return (
    <MUISelect
      label={label}
      name={name}
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      {...rest}
    >
      {children}
    </MUISelect>
  );
}
