import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import {
  Logo, LoginModal, LoggedMenu, HeaderMenu,
} from 'components';
import { Container } from 'common/Container';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slicers/user/userSlice';

export function Header() {
  const theme = useTheme();
  const user = useSelector(selectUser);

  return (
    <Box height="100%" overflow="hidden" width="100%">
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.background.topbar,
        }}
        elevation={0}
      >
        <Container paddingY={{ xs: 1 / 2, sm: 1 }} maxWidth={{ md: '980px' }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Link
              component={RouterLink}
              to="/forum/pan"
              underline="none"
            >
              <Logo />
            </Link>
            <Box display="flex" alignItems="center">
              <HeaderMenu />
              {user ? <LoggedMenu /> : <LoginModal />}
            </Box>
          </Box>
        </Container>
        <Divider />
      </AppBar>
    </Box>
  );
}
