import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getGroupTopics, getPopularTopicsByGroupId, getGroupFeaturedTopics } from 'services/providers/pandlr.provider';
import { NewTopicModal } from 'components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import List from '@mui/material/List';
import { Box, Typography } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import IconButton from '@mui/material/IconButton';
import { setSnackbar } from 'redux/slicers';
import * as S from './styles';
import { ForumTopicPagination } from './ForumTopicsPagination';
import { ForumTopicsSkeleton } from './ForumTopicsSkeleton';
import { LoginModal } from '../Modals';
import { ForumSingleTopic } from './ForumSingleTopic';

export function ForumTopics() {
  const [topics, setTopics] = useState({ latestTopics: [], popularTopics: [] });
  const groupSlug = 'pan';
  const id = useSelector((state) => state.forumDetails?.id);
  const { isLogged } = useSelector((state) => state.user);
  const { pageNumber = '1' } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const paginationRef = useRef({
    currentPage: +pageNumber,
    totalPages: 10,
    totalItems: 1,
  });

  const fetchGroupTopics = async (page) => {
    if (!id) return;
    Promise.all([
      getGroupTopics(id, page),
      getGroupFeaturedTopics(id),
      getPopularTopicsByGroupId(id, page),
    ]).then((results) => {
      const [latestTopics, featuredTopics, popularTopics] = results;
      if (latestTopics.success && popularTopics.success && featuredTopics.success) {
        const { items: latestItems, ...latestPaginationOptions } = latestTopics.data;
        const popularItems = popularTopics.data.items;
        const featuredItems = featuredTopics.data.items;
        paginationRef.current = latestPaginationOptions;
        setTopics({
          latestTopics: latestItems,
          popularTopics: popularItems,
          featuredTopics: featuredItems,
        });
      }
    });
  };

  useEffect(() => {
    fetchGroupTopics(pageNumber);
    return () => setTopics([]);
  }, [pathname, id]);

  const handlePageChange = (page) => {
    if (!pathname.includes('/page/')) { return navigate(`${pathname}/page/${page}`); }
    return navigate(`${pathname.replace(`page/${pageNumber}`, `page/${page}`)}`);
  };

  return (
    <>
      <S.ForumTopicsContainer>
        <Box
          sx={{
            display: 'flex', justifyContent: 'space-between', ml: { xs: 1, sm: 2 }, mt: 1, mb: -1.5,
          }}
        >
          {isLogged ? <NewTopicModal /> : <LoginModal text="New Topic" />}
          <Box>
            <IconButton
              onClick={async () => {
                await fetchGroupTopics(1);
                dispatch(setSnackbar({ message: 'Topics refreshed', severity: 'success' }));
              }}
              sx={{ color: 'text.secondary', mr: 1 }}
            >
              <AutorenewIcon />
            </IconButton>
          </Box>
        </Box>
        {pageNumber === '1' && (
          <List id="topic-featured-list" sx={{ width: '100%', bgcolor: 'background.level2', mb: -3 }}>
            {topics.featuredTopics?.map((topic) => (
              <ForumSingleTopic
                key={`topic-${topic.id}`}
                topic={topic}
                groupSlug={groupSlug}
                isFeatured
              />
            ))}
          </List>
        )}
        <List id="topic-list" sx={{ width: '100%', bgcolor: 'background.level2' }}>
          {!topics.latestTopics?.length && <ForumTopicsSkeleton />}
          {topics.latestTopics?.map((topic) => (
            <ForumSingleTopic
              key={`topic-${topic.id}`}
              topic={topic}
              groupSlug={groupSlug}
            />
          ))}
        </List>
        <ForumTopicPagination
          onSelectPage={handlePageChange}
          totalPages={paginationRef.current.totalPages}
        />
      </S.ForumTopicsContainer>
      <S.ForumTopicsContainer>
        {
          (topics.latestTopics?.length > 0 && topics.popularTopics?.length > 0) && (
            <>
              <Typography variant="h5" sx={{ mt: 2, ml: 2, zIndex: 100 }}>Tópicos Populares</Typography>
              <List id="topic-list-popular" sx={{ width: '100%', bgcolor: 'background.level2' }}>
                {!topics.popularTopics?.length && <ForumTopicsSkeleton />}
                {topics.popularTopics?.map((topic) => (
                  <ForumSingleTopic
                    key={`topic-${topic.id}`}
                    topic={topic}
                    groupSlug={groupSlug}
                  />
                ))}
              </List>
              <ForumTopicPagination
                onSelectPage={handlePageChange}
                totalPages={paginationRef.current.totalPages}
              />
            </>
          )
        }
      </S.ForumTopicsContainer>
    </>
  );
}
