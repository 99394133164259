import { useState } from 'react';
import { BanModal } from 'components';
import { paperProps } from 'utils/paperProps';
import {
  Menu, MenuItem, ListItemIcon, Avatar,
} from '@mui/material';
import { promoteGroupUser, demoteGroupUser } from 'services/providers/pandlr.provider';
import { useSelector, useDispatch } from 'react-redux';
import {
  AccountBox, ThumbUp, ThumbDown, RemoveCircle,
} from '@mui/icons-material';
import { setSnackbar } from 'redux/slicers';
import { useNavigate } from 'react-router-dom';

export function MenuUserAvatar({ user, ...props }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { topicDetails } = useSelector((state) => state);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePromote = async () => {
    const result = await promoteGroupUser(
      { userId: user.id, groupId: topicDetails.groupId },
    );
    if (result.success) return dispatch(setSnackbar({ message: result.data.message, status: 'success' }));
    return dispatch(setSnackbar({ message: result.data.message, status: 'error' }));
  };

  const handleDemote = async () => {
    const result = await demoteGroupUser(
      { userId: user.id, groupId: topicDetails.groupId },
    );
    if (result.success) return dispatch(setSnackbar({ message: result.data.message, status: 'success' }));
    return dispatch(setSnackbar({ message: result.data.message, status: 'error' }));
  };

  const handleViewProfile = () => {
    navigate(`/user/${user.username}`);
  };

  return (
    <>
      <Avatar
        onClick={handleClick}
        {...props}
      />
      <Menu
        id="reply-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={paperProps}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleViewProfile}>
          <ListItemIcon>
            <AccountBox sx={{
              width: 20,
            }}
            />
          </ListItemIcon>
          Ver perfil
        </MenuItem>
        {topicDetails.userCredentials?.isModerator
          && (
            <div>
              <BanModal
                userId={user.id}
              />
              <MenuItem onClick={() => {
                alert('Opção ainda não implementada');
                handleClose();
              }}
              >
                <ListItemIcon>
                  <RemoveCircle />
                </ListItemIcon>
                Desbanir
              </MenuItem>
              <MenuItem onClick={handlePromote}>
                <ListItemIcon>
                  <ThumbUp />
                </ListItemIcon>
                Promover
              </MenuItem>
              <MenuItem onClick={handleDemote}>
                <ListItemIcon>
                  <ThumbDown />
                </ListItemIcon>
                Demotar
              </MenuItem>
            </div>
          )}
      </Menu>
    </>
  );
}
