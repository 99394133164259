import { Divider } from '@mui/material';
import ReplySkeleton from 'components/Reply/ReplySkeleton';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { getUserReplies } from 'services/providers/pandlr.provider';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as S from './styles';
import { UserReplyCard } from './UserReplyCard';

export function UserReplies() {
  const [userReplies, setUserReplies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    user: { id: userId },
    isLoading: isLoadingProfile,
  } = useSelector((state) => state.userProfile);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
  });

  const fetchUserReplies = async (page) => {
    if (!userId || isLoadingProfile) return;
    setIsLoading(true);
    const res = await getUserReplies(userId, page);
    if (res.success) {
      const { items, ...paginationOptions } = res.data;
      setUserReplies((prevReplies) => {
        if (prevReplies.length > 70) {
          return [...prevReplies.slice(30), ...items];
        }
        return [...prevReplies, ...items];
      });
      setPagination(paginationOptions);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setUserReplies([]);
    fetchUserReplies(1);

    return () => {
      setUserReplies([]);
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    };
  }, [userId, isLoadingProfile]);

  const HAS_MORE = !!pagination.totalPages === 0 || pagination.currentPage < pagination.totalPages;

  const renderSkeletons = () => Array(5).fill(0).map(() => (
    <div key={`reply-skeleton-${uuidv4()}`}>
      <ReplySkeleton />
      <Divider variant="middle" />
    </div>
  ));

  return (
    <S.UserRepliesContainer>
      {isLoading && renderSkeletons()}
      <InfiniteScroll
        dataLength={userReplies.length}
        pageStart={0}
        next={() => fetchUserReplies(pagination.currentPage + 1)}
        useWindow
        hasMore={HAS_MORE}
        loader={userReplies.length > 0 && renderSkeletons()}
      >
        {userReplies?.map((reply) => (
          <UserReplyCard key={`reply-${reply.id}`} reply={reply} />
        ))}
      </InfiniteScroll>
    </S.UserRepliesContainer>
  );
}
