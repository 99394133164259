/* eslint-disable react/prop-types */
import {
  Box, Grid, Typography, Link as MUILink, TextField as MUITextField,
} from '@mui/material';
import MUILoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { Container } from 'common/Container';
import { ControlledSelect, ControlledTextField } from '../ControlledFormComponents';

export function Form({ children, ...rest }) {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Container maxWidth={600}>
        <form {...rest}>
          {children}
        </form>
      </Container>
    </Box>
  );
}

export function FormTitle({ children }) {
  return (
    <Typography variant="h4" component="h1" textAlign="center" gutterBottom>
      {children}
    </Typography>
  );
}

export function FormSubtitle({ children }) {
  return (
    <Typography variant="h6" component="h2" textAlign="center" gutterBottom>
      {children}
    </Typography>
  );
}

export function FormContainer({ children }) {
  return (
    <Grid container spacing={4}>
      {children}
    </Grid>
  );
}

export function Input({ children }) {
  return (
    <Grid item xs={12}>
      {children}
    </Grid>
  );
}

export function InputGrid({ children }) {
  return (
    <Grid item xs={12} md={6}>
      {children}
    </Grid>
  );
}

export function UploadContainer({ children }) {
  return (
    <Box display="flex">
      {children}
    </Box>
  );
}

export function LabelUpload({ children }) {
  return (
    <Typography variant="subtitle2" sx={{ marginTop: 1 }}>
      {children}
    </Typography>
  );
}

export function LabelText({ children }) {
  return (
    <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>
      {children}
    </Typography>
  );
}

export function SimpleText({ children }) {
  return (
    <Typography variant="subtitle2">
      {children}
    </Typography>
  );
}

export function TextField(props) {
  return (
    <ControlledTextField
      variant="outlined"
      fullWidth
      {...props}
    />
  );
}

export function SignUp({ children }) {
  return (
    <Grid item container xs={12}>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'stretched', sm: 'center' }}
        justifyContent="space-between"
        width="100%"
        maxWidth={600}
        margin="0 auto"
      >
        {children}
      </Box>
    </Grid>
  );
}

export function Link({ children, ...rest }) {
  return (
    <MUILink
      color="primary"
      underline="none"
      sx={{
        cursor: 'pointer',
      }}
      {...rest}
    >
      {children}
    </MUILink>
  );
}

export function Select({ children, ...rest }) {
  return (
    <ControlledSelect
      fullWidth
      {...rest}
    >
      {children}
    </ControlledSelect>
  );
}

export function Agreement({ children }) {
  return (
    <Grid item container xs={12}>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'stretched', sm: 'center' }}
        justifyContent="space-between"
        width="100%"
        maxWidth={600}
        margin="0 auto"
      >
        <Box>
          <Typography variant="subtitle2">
            {children}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}

export function FormActions({ children }) {
  return (
    <Grid item container xs={12}>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'stretched', sm: 'center' }}
        justifyContent="space-between"
        width="100%"
        maxWidth={600}
        margin="0 auto"
      >
        {children}
      </Box>
    </Grid>
  );
}

export function LoadingButton({ children, ...rest }) {
  return (
    <Box display="flex">
      <MUILoadingButton
        size="large"
        endIcon={<SendIcon />}
        loadingPosition="end"
        variant="contained"
        {...rest}
      >
        {children}
      </MUILoadingButton>
    </Box>
  );
}

export function UpdateUserFormContainer({ children }) {
  return (
    <Box
      width="100%"
      margin="0 auto"
      mt={{ xs: 2, sm: 6 }}
      p={{ xs: 2, sm: 4 }}
      bgcolor="background.level2"
      borderRadius="5px"
      boxShadow={(theme) => theme.shadows[1]}
    >
      {children}
    </Box>
  );
}

export function WidgetPreferencesContainer({ children }) {
  return (
    <Box
      width="100%"
      margin="0 auto"
      mt={{ xs: 2, sm: 6 }}
      p={{ xs: 2, sm: 4 }}
      bgcolor="background.level2"
      borderRadius="5px"
      boxShadow={(theme) => theme.shadows[1]}
    >
      {children}
    </Box>
  );
}

export function Textarea({ children, ...rest }) {
  return (
    <MUITextField
      multiline
      minRows={2}
      maxRows={4}
      {...rest}
    />
  );
}
