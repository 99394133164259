import { RegistrationForm } from 'components/Forms';
import React from 'react';
import * as S from './styles';

export default function RegisterModal({ open }) {
  return (
    <S.Modal
      open={open}
      modalName={S.MODAL_STYLES.REGISTER_MODAL}
    >
      <RegistrationForm />
    </S.Modal>
  );
}
