import React, { useState } from 'react';
import { GifOptions } from 'components/Menus';
import { purifyContent } from 'utils/PostUtils';
import { CardContainer } from 'common/CardContainer';
import { Divider } from '@mui/material';
import * as S from './styles';

export function UserReplyCard({ reply }) {
  const [showGifOptions, setShowGifOptions] = useState({
    show: false, image: '', UUID: '', positions: { top: '', left: '' },
  });

  const replyContent = purifyContent(reply.content, setShowGifOptions);

  return (
    <>
      <GifOptions
        showGifOptions={showGifOptions}
        setShowGifOptions={setShowGifOptions}
      />
      <CardContainer>
        <S.CardHeader reply={reply} />
        <S.CardContent reply={reply}>
          {replyContent}
        </S.CardContent>
        <S.CardActions>
          <S.ViewOnTopic reply={reply} />
        </S.CardActions>
        <Divider variant="middle" />
      </CardContainer>
    </>
  );
}
