import React from 'react';
import { Avatar as MuiAvatar } from '@mui/material';

export function Avatar({ avatarUrl, ...rest }) {
  return (
    <MuiAvatar
      {...rest}
      src={avatarUrl}
    />
  );
}
