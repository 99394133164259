import { Box } from '@mui/material';
import { Container } from 'common/Container';

export function MentionsContainer({ children }) {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      marginTop={{ xs: 7, sm: 8, md: 15 }}
    >
      <Container maxWidth={980}>
        {children}
      </Container>
    </Box>
  );
}

export function MentionsInteractionContainer({ children }) {
  return (
    <Box
      bgcolor="background.level2"
      borderRadius="5px"
      boxShadow={(theme) => theme.shadows[1]}
      sx={{
        mt: { xs: 2, md: 4 },
      }}
      width="100%"
    >
      {children}
    </Box>
  );
}
