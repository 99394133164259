import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MoreVert, Block, Settings } from '@mui/icons-material';
import { Box, IconButton, ListItemIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export function ForumModeratosMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { forumDetails } = useSelector((state) => state);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBannedUsers = () => {
    navigate(`/forum/banned-users/${forumDetails.slug}/${forumDetails.id}`);
  };

  const handleManageForum = () => {
    navigate(`/forum/manage/${forumDetails.slug}`);
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        right: 0,
        top: 0,
      }}
    >
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleBannedUsers}>
          <ListItemIcon>
            <Block fontSize="small" />
          </ListItemIcon>
          Banidos
        </MenuItem>
        <MenuItem onClick={handleManageForum}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Gerenciar
        </MenuItem>
      </Menu>
    </Box>
  );
}
