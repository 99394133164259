import React, { useEffect, useState } from 'react';
import { IconButton, TextField } from '@mui/material';
import { PhotoCamera, SaveAs } from '@mui/icons-material';
import {
  getGroupDetails, updateForumDetails, uploadForumBanner, uploadForumImage,
} from 'services/providers/pandlr.provider';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/slicers';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as S from './styles';

export function UpdateForumForm() {
  const [avatarUpload, setAvatarUpload] = useState(null);
  const [backgroundUpload, setBackgroundUpload] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { slug } = useParams();
  const [forumDetails, setForumDetails] = useState({});

  const INITIAL_STATE = {
    description: forumDetails.description || '',
    avatar: '',
    background: '',
    avatarLink: forumDetails.avatarLink || '',
  };

  const [data, setData] = useState(INITIAL_STATE);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const fetchForumDetails = async () => {
    const res = await getGroupDetails(slug, 1);

    if (!res.success) {
      dispatch(setSnackbar({ message: res.message, status: 'error' }));
      return;
    }

    setForumDetails(res.data);
    setData(res.data);
  };

  useEffect(() => {
    fetchForumDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevState) => ({
      ...prevState,
      [name]: !value || value.length < 3 || value.length > 200,
    }));

    if (name === 'avatarLink') {
      const linkRegex = /https?:\/\/.+/;
      setErrors((prevState) => ({
        ...prevState,
        [name]: !value || !linkRegex.test(value),
      }));
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
  };

  const uploadAvatar = async () => {
    const formData = new FormData();
    formData.append('image', data.avatar);

    const res = await uploadForumImage(formData, forumDetails.id);

    if (!res.success) {
      dispatch(setSnackbar({ message: res.data.message, status: 'error' }));
      setAvatarUpload(null);
      setIsSubmitting(false);
      return null;
    }

    setAvatarUpload(res.data);

    return res.data;
  };

  const uploadBackground = async () => {
    const formData = new FormData();
    formData.append('image', data.background);

    const res = await uploadForumBanner(formData, forumDetails.id);

    if (!res.success) {
      dispatch(setSnackbar({ message: res.data.message, status: 'error' }));
      setBackgroundUpload(null);
      setIsSubmitting(false);
      return null;
    }

    setBackgroundUpload(res.data.path);

    return res.data.path;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let image = null;
    let background = null;

    if (avatarUpload?.path) {
      image = avatarUpload;
    } else if (data.avatar) {
      image = await uploadAvatar();
    } else {
      image = {
        path: forumDetails.avatarThumb,
        path300: forumDetails.avatar,
      };
    }

    if (backgroundUpload?.path) {
      background = backgroundUpload;
    } else if (data.background) {
      background = await uploadBackground();
    } else {
      background = forumDetails.background;
    }

    if (!image) return;

    const result = await updateForumDetails({
      description: data.description,
      groupImage: image.path300,
      groupImageThumb: image.path,
      groupBackgroundImage: background,
      avatarLink: data.avatarLink,
    }, forumDetails.id);

    if (!result.success) {
      setIsSubmitting(false);
      setErrors((prevState) => ({
        ...prevState,
        description: result.data.message,
      }));

      dispatch(setSnackbar({ message: result.data.message, status: 'error' }));

      return;
    }

    dispatch(setSnackbar({ message: 'Informações do grupo atualizadas com sucesso!', status: 'success' }));

    setAvatarUpload(null);
    setIsSubmitting(false);
    navigate(`/forum/${slug}`);
  };

  const AVATAR_URL = data.avatar ? URL.createObjectURL(data.avatar) : `${process.env.REACT_APP_IMAGE_URL}/${forumDetails.groupImage}`;
  const BANNER_URL = data.background ? URL.createObjectURL(data.background) : `${process.env.REACT_APP_IMAGE_URL}/${forumDetails.groupBackgroundImage}`;

  return (
    <>
      <S.UserContainer>
        <S.UserImagesContainer>
          <S.BannerImage src={BANNER_URL} />
          <S.UserAvatarContainer>
            <Link to={forumDetails.avatarLink || '#'}>
              <S.ForumImage
                src={AVATAR_URL}
                alt="Imagem do forum"
              />
            </Link>
          </S.UserAvatarContainer>
          <S.UserInfosContainer>
            <S.UserName>
              {forumDetails?.name}
            </S.UserName>
          </S.UserInfosContainer>
        </S.UserImagesContainer>
        <S.UserDetailsContainer>
          <S.UserNameMobile>
            {forumDetails?.name}
          </S.UserNameMobile>
          <S.ForumDescription>
            {data.description || forumDetails.description}
          </S.ForumDescription>
        </S.UserDetailsContainer>
      </S.UserContainer>

      <S.UpdateUserFormContainer>
        <S.Form onSubmit={onSubmit}>
          <S.FormContainer>
            <S.Input>
              <S.Textarea
                variant="outlined"
                fullWidth
                label="Descrição"
                name="description"
                value={data.description}
                onChange={handleChange}
                error={!!errors.description}
              />
            </S.Input>

            <S.Input>
              <S.LabelUpload>
                Carregue uma imagem para o avatar do Forum
              </S.LabelUpload>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input name="avatar" onChange={handleFileChange} hidden accept="image/*" type="file" />
                <PhotoCamera />
              </IconButton>
            </S.Input>

            <S.Input>
              <TextField
                variant="outlined"
                fullWidth
                label="Link do Avatar"
                name="avatarLink"
                value={data.avatarLink}
                onChange={handleChange}
                error={!!errors.avatarLink}
              />
            </S.Input>

            <S.Input>
              <S.LabelUpload>
                Carregue uma imagem para o background do Forum
              </S.LabelUpload>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input name="background" onChange={handleFileChange} hidden accept="image/*" type="file" />
                <PhotoCamera />
              </IconButton>
            </S.Input>

            <S.FormActions>
              <S.LoadingButton
                disabled={((
                  !data.description
                  && !data.avatar && data.description === forumDetails.description
                  && !data.background)
                  || isSubmitting || errors.description || errors.avatarLink
                )}
                type="submit"
                loading={isSubmitting}
                endIcon={<SaveAs />}
              >
                Salvar
              </S.LoadingButton>
            </S.FormActions>

          </S.FormContainer>
        </S.Form>
      </S.UpdateUserFormContainer>
    </>

  );
}
