import { configureStore } from '@reduxjs/toolkit';
import storeSynchronize from 'redux-localstore';
import {
  topicPaginationSlice,
  userProfileSlice,
  darkModeSlice,
  forumDetailsSlice,
  topicDetailsSlice,
  userSlice,
  gifOptionsSlice,
  quoteReplySlice,
  textEditorSlice,
  fastReplySlice,
  snackbarsSlice,
  autoScrollSlice,
  infiniteScrollSlice,
  gifPocketSlice,
  selectedImageSlice,
  addToGifPocketSlice,
  userPreferencesSlice,
} from './slicers';

export const store = configureStore({
  reducer: {
    darkMode: darkModeSlice.reducer,
    forumDetails: forumDetailsSlice.reducer,
    topicDetails: topicDetailsSlice.reducer,
    user: userSlice.reducer,
    gifOptions: gifOptionsSlice.reducer,
    quoteReply: quoteReplySlice.reducer,
    topicPagination: topicPaginationSlice.reducer,
    textEditor: textEditorSlice.reducer,
    fastReply: fastReplySlice.reducer,
    snackbars: snackbarsSlice.reducer,
    autoScroll: autoScrollSlice.reducer,
    infiniteScroll: infiniteScrollSlice.reducer,
    userProfile: userProfileSlice.reducer,
    gifPocket: gifPocketSlice.reducer,
    selectedImage: selectedImageSlice.reducer,
    addToGifPocket: addToGifPocketSlice.reducer,
    userPreferences: userPreferencesSlice.reducer,
  },
});

storeSynchronize(store, {
  whitelist: ['darkMode', 'user', 'autoScroll', 'infiniteScroll', 'fastReply', 'userPreferences'],
  blacklist: ['forumDetails', 'topicDetails', 'gifOptions', 'quoteReply', 'topicPagination', 'textEditor', 'snackbars', 'gifPocket', 'selectedImage', 'addToGifPocket'],
});
