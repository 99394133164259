import { useState, useEffect } from 'react';
import { getGifPocketList } from 'services/providers/pandlr.provider';
import {
  Box, Button, IconButton, TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setGifPocket } from 'redux/slicers';
import { GifShortcutCard, AddGifToGifPocket } from 'components';
import CloseIcon from '@mui/icons-material/Close';
import * as S from './styles';

export function GifPocketModal({ isCreatingTopic = false }) {
  const [open, setOpen] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [searchInput, setSearchInput] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const { gifPocket } = useSelector((state) => state);

  const gifPocketFiltered = gifPocket
    .filter((gif) => gif.shortcut.toLowerCase().includes(searchInput.toLowerCase()));

  useEffect(() => {
    (async () => {
      const result = await getGifPocketList();
      if (result.success) {
        dispatch(setGifPocket(result.data));
      }
    })();
  }, []);

  return (
    <>
      <S.GifPocketBtn handleOpen={handleOpen} isCreatingTopic={isCreatingTopic} />
      <S.Modal
        open={open}
        handleClose={handleClose}
        modalName={S.MODAL_STYLES.GIF_POCKET_MODAL}
        title="Gif Pocket"
        className="gif-pocket-modal"
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ mb: 3 }}>
          {showAddForm
            ? (
              <AddGifToGifPocket
                setShowAddForm={setShowAddForm}
              />
            )
            : (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                {(!isDeleting && !isEditing) && (
                  <Button variant="contained" onClick={() => setShowAddForm(true)}>
                    Adicionar
                  </Button>
                )}
                <Button
                  disabled={isDeleting}
                  sx={{ ml: 2 }}
                  variant="contained"
                  onClick={() => setIsEditing((prev) => !prev)}
                >
                  {isEditing ? 'Parar de editar' : 'Editar'}
                </Button>
                <Button disabled={isEditing} variant="contained" sx={{ ml: 2 }} color="error" onClick={() => setIsDeleting((prev) => !prev)}>
                  {isDeleting ? 'Parar de deletar' : 'Deletar'}
                </Button>
              </Box>
            )}
        </Box>
        <Box sx={{ minHeight: '40vh' }}>
          <TextField
            fullWidth
            sx={{ mb: 2 }}
            variant="outlined"
            size="small"
            placeholder="Buscar"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <Box sx={{
            display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around',
          }}
          >
            {gifPocketFiltered.length > 0 && gifPocketFiltered.map((e) => (
              <GifShortcutCard
                key={`gif-pocket-${e.id}`}
                id={e.id}
                shortcut={e.shortcut}
                imageUrl={e.imageUrl}
                thumbnailUrl={e.thumbnailUrl}
                handleClose={handleClose}
                isDeleting={isDeleting}
                isEditing={isEditing}
              />
            ))}
            {!gifPocketFiltered.length && 'Nenhum atalho encontrado!'}
          </Box>
        </Box>
      </S.Modal>
    </>
  );
}
