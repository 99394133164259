/* eslint-disable no-return-assign */
import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setGifOptions, setQuoteReply, setEditorId, setSelectedImage,
} from 'redux/slicers';

export function TextEditorDarkFixed({ value, setValue }) {
  const dispatch = useDispatch();

  const {
    gifPocket, gifOptions, quoteReply, selectedImage,
  } = useSelector((state) => state);

  const editorRef = useRef(null);

  function transformValue(checkValue, gifPocketFiles) {
    let transformedValue = checkValue;
    gifPocketFiles.forEach((gifPocketFile) => {
      const regex = new RegExp(gifPocketFile.shortcut, 'g');
      transformedValue = transformedValue.replace(regex, `<img alt="Gif Pocket" src="${gifPocketFile.imageUrl}" />`);
    });
    return transformedValue;
  }

  const handleUpdate = (newValue) => {
    const valueWithShortcut = transformValue(newValue, gifPocket);
    setValue(valueWithShortcut);
  };

  useEffect(() => {
    if (gifOptions.image) {
      setValue(`${value} <img src="${gifOptions.image}" />`);
      dispatch(setGifOptions({ image: '' }));
    }
  }, [gifOptions.image]);

  useEffect(() => {
    if (quoteReply.content) {
      setValue(`${value} <blockquote>@${quoteReply.username} <br /> ${quoteReply.content}</blockquote> <br />`);
      dispatch(setQuoteReply({ content: '' }));
    }
  }, [quoteReply.content]);

  const onInit = (_e, editor) => {
    editorRef.current = editor;
    dispatch(setEditorId(editor.id));
  };

  useEffect(() => {
    if (selectedImage) {
      setValue(`${value} <img src="${selectedImage}" />`);
      dispatch(setSelectedImage(''));
    }
  }, [selectedImage]);

  return (
    <Editor
      onInit={(e, editor) => onInit(e, editor)}
      plugins="code image"
      toolbar="image bold italic underline strikethrough alignleft aligncenter alignright alignjustify fontsizeselect forecolor backcolor removeformat undo redo | code"
      init={{
        selector: 'textarea',
        autoresize_bottom_margin: 15,
        branding: false,
        menubar: false,
        statusbar: false,
        toolbar_mode: 'sliding',
        height: 350,
        skin: 'oxide-dark',
        content_css: 'dark',
      }}
      initialValue=""
      value={value}
      onEditorChange={handleUpdate}
    />
  );
}
