import { pandlrAPI } from '../api/pandlr';
import request from '../http/request';

export const createNewAccount = async (userInfos) => request(
  pandlrAPI,
  '/users/create-new-account',
  userInfos,
  201,
  'post',
  true,
);

export const updateUserInfos = async (userInfos) => request(
  pandlrAPI,
  '/users/update-user',
  userInfos,
  201,
  'put',
  true,
);

export const updateUserProfile = async (userInfos) => request(
  pandlrAPI,
  '/users/update-user-profile',
  userInfos,
  201,
  'put',
  true,
);

export const login = async (userInfos) => request(
  pandlrAPI,
  '/users/login',
  userInfos,
  200,
  'post',
  true,
);

export const loginWithGoogle = async (googleToken) => request(
  pandlrAPI,
  '/users/login-with-google',
  { googleToken },
  200,
  'post',
  true,
);

export const logout = async () => request(
  pandlrAPI,
  '/users/logout',
  null,
  200,
  'get',
  true,
);

export const getAllUsers = async () => request(
  pandlrAPI,
  '/users',
  null,
  200,
  'get',
);

export const getGroupDetails = async (groupSlug, userId) => request(
  pandlrAPI,
  `/groups/details/${groupSlug}/${userId}`,
  null,
  200,
  'get',
);

export const getGroupTopics = async (groupId, page) => request(
  pandlrAPI,
  `/groups/topics/${groupId}?page=${page}`,
  null,
  200,
  'get',
);

export const getGroupFeaturedTopics = async (groupId) => request(
  pandlrAPI,
  `/groups/featured-topics/${groupId}`,
  null,
  200,
  'get',
);

export const getTopicDetails = async (topicId, userId) => request(
  pandlrAPI,
  `/topics/details/${topicId}/${userId}`,
  null,
  200,
  'get',
);

export const getTopicReplies = async (topicId, page) => request(
  pandlrAPI,
  `/topics/replies/${topicId}?page=${page}`,
  null,
  200,
  'get',
);

export const uploadUserAvatar = async (FormData) => {
  try {
    const res = await pandlrAPI
      .post('/users/upload-avatar', FormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });

    return {
      success: res.status === 200,
      data: res.data,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || 'Something went wrong',
    };
  }
};

export const uploadUserBackground = async (FormData) => {
  try {
    const res = await pandlrAPI
      .post('/users/upload-background', FormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });

    return {
      success: res.status === 200,
      data: res.data,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || 'Something went wrong',
    };
  }
};

export const joinGroup = async (groupId) => request(
  pandlrAPI,
  `/groups/join/${groupId}`,
  null,
  200,
  'get',
  true,
);

export const leaveGroup = async (groupId) => request(
  pandlrAPI,
  `/groups/leave/${groupId}`,
  null,
  200,
  'get',
  true,
);

export const createNewTopic = async ({ title, content, forumId }) => request(
  pandlrAPI,
  `/topics/new/${forumId}`,
  { title, content },
  201,
  'post',
  true,
);

export const editTopic = async ({
  title, content, topicId, forumId,
}) => request(
  pandlrAPI,
  `/topics/edit/${topicId}/${forumId}`,
  { title, content },
  201,
  'put',
  true,
);

export const createNewReply = async ({
  content, topicId, forumId, userInfo,
}) => request(
  pandlrAPI,
  `/topics/reply/new/${topicId}/${forumId}`,
  { content, userInfo },
  201,
  'post',
  true,
);

export const forumBanUser = async ({ groupId, userId, reason }) => request(
  pandlrAPI,
  `/groups/ban/${groupId}/${userId}`,
  { reason },
  200,
  'post',
  true,
);

export const forumTopicDelete = async ({ topicId, groupId }) => request(
  pandlrAPI,
  `/topics/delete/${topicId}/${groupId}`,
  null,
  200,
  'get',
  true,
);

export const forumReplyDelete = async ({ replyId, groupId }) => request(
  pandlrAPI,
  `/topics/reply/delete/${replyId}/${groupId}`,
  null,
  200,
  'get',
  true,
);

export const serverCheck = async () => request(
  pandlrAPI,
  '/check-cores',
  null,
  200,
  'get',
  true,
);

export const promoteGroupUser = async ({ groupId, userId }) => request(
  pandlrAPI,
  `/groups/promote/${groupId}/${userId}`,
  null,
  200,
  'get',
  true,
);

export const demoteGroupUser = async ({ groupId, userId }) => request(
  pandlrAPI,
  `/groups/demote/${groupId}/${userId}`,
  null,
  200,
  'get',
  true,
);

export const closeTopic = async ({ topicId, groupId }) => request(
  pandlrAPI,
  `/topics/close/${topicId}/${groupId}`,
  null,
  200,
  'get',
  true,
);

export const openTopic = async ({ topicId, groupId }) => request(
  pandlrAPI,
  `/topics/open/${topicId}/${groupId}`,
  null,
  200,
  'get',
  true,
);

export const getPopularTopicsByGroupId = async (groupId, page) => request(
  pandlrAPI,
  `/groups/popular-topics/${groupId}?page=${page}`,
  null,
  200,
  'get',
);

export const getUserByUsername = async (username) => request(
  pandlrAPI,
  `/users/username/${username}`,
  null,
  200,
  'get',
);

export const getUserReplies = async (userId, page) => request(
  pandlrAPI,
  `/users/replies/${userId}?page=${page}`,
  null,
  200,
  'get',
);

export const getUserTopics = async (userId, page) => request(
  pandlrAPI,
  `/users/topics/${userId}?page=${page}`,
  null,
  200,
  'get',
);

export const addNewGifToGifPocket = async ({ shortcut, imageUrl }) => request(
  pandlrAPI,
  '/users/gif-pocket/add',
  { shortcut, imageUrl },
  201,
  'post',
  true,
);

export const getGifPocketList = async () => request(
  pandlrAPI,
  '/users/gif-pocket/list',
  null,
  200,
  'get',
  true,
);

export const deleteGifFromGifPocket = async (gifId) => request(
  pandlrAPI,
  `/users/gif-pocket/delete/${gifId}`,
  null,
  200,
  'delete',
  true,
);

export const updateGifShortcut = async ({ gifId, shortcut }) => request(
  pandlrAPI,
  `/users/gif-pocket/update/${gifId}`,
  { shortcut },
  200,
  'put',
  true,
);

export const getForumBans = async (groupId, page) => request(
  pandlrAPI,
  `/groups/bans/${groupId}?page=${page}`,
  null,
  200,
  'get',
  true,
);

export const unbanForumUser = async ({ groupId, userId }) => request(
  pandlrAPI,
  `/groups/unban/${groupId}/${userId}`,
  null,
  200,
  'get',
  true,
);

export const getMentionsByUserId = async (username, page) => request(
  pandlrAPI,
  `/users/mentions/${username}?page=${page}`,
  null,
  200,
  'get',
);

export const pinTopicByTopicId = async ({ topicId, groupId }) => request(
  pandlrAPI,
  `/topics/pin/${topicId}/${groupId}`,
  null,
  200,
  'get',
  true,
);

export const unpinTopicByTopicId = async ({ topicId, groupId }) => request(
  pandlrAPI,
  `/topics/unpin/${topicId}/${groupId}`,
  null,
  200,
  'get',
  true,
);

export const uploadForumImage = async (formData, forumId) => request(
  pandlrAPI,
  `/groups/upload-image/${forumId}`,
  formData,
  200,
  'post',
  true,
);

export const uploadForumBanner = async (formData, forumId) => request(
  pandlrAPI,
  `/groups/upload-banner/${forumId}`,
  formData,
  200,
  'post',
  true,
);

export const updateForumDetails = async (data, groupId) => request(
  pandlrAPI,
  `/groups/edit-details/${groupId}`,
  data,
  200,
  'put',
  true,
);
