import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Description, AllInclusive } from '@mui/icons-material';
import { setInfiniteScroll } from 'redux/slicers';
import {
  IconButton, ListItemIcon, MenuItem, Tooltip,
} from '@mui/material';

export function InfiniteScrollBtn({ isMenuItem = false, onClick }) {
  const { infiniteScroll } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleClick = (bool) => {
    onClick?.();
    dispatch(setInfiniteScroll(bool));
  };

  return isMenuItem ? (
    <MenuItem
      sx={{ display: 'flex' }}
      onClick={() => handleClick(!infiniteScroll)}
    >
      <ListItemIcon>
        {infiniteScroll ? <Description /> : <AllInclusive />}
      </ListItemIcon>
      {infiniteScroll ? 'Paginação normal' : 'Paginação infinita'}
    </MenuItem>
  ) : (
    <Tooltip
      arrow
      title={
        infiniteScroll ? 'Paginação normal' : 'Paginação infinita'
      }
    >
      <IconButton
        color="primary"
        aria-label="infinite scroll"
        component="label"
        onClick={() => handleClick(!infiniteScroll)}
      >
        {infiniteScroll ? <Description /> : <Description />}
      </IconButton>
    </Tooltip>
  );
}
