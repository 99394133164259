import { useGoogleLogin } from '@react-oauth/google';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPendingUser, setUser } from 'redux/slicers';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';
import { loginWithGoogle } from '../../services/providers/pandlr.provider';
import RegisterModal from './RegisterModal';

export function LoginModal({ text = 'Login', description = 'Login' }) {
  const [open, setOpen] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const googleLogin = useGoogleLogin({
    onSuccess: async (credentials) => {
      const res = await loginWithGoogle(credentials.access_token);
      if (res.success) {
        if (res.data.status === 'pending') {
          dispatch(setPendingUser(res.data));
          setOpenRegister(true);
          return;
        }

        dispatch(setUser(res.data));
        handleClose();
        navigate('/forum/pan');
      }
    },
  });

  return (
    <div id="login-pandlr">
      <S.Button handleOpen={handleOpen}>
        {text}
      </S.Button>
      <S.Modal
        open={open}
        handleClose={handleClose}
        modalName={S.MODAL_STYLES.LOGIN_MODAL}
        title={description}
      >
        <S.GoogleButton
          onClick={googleLogin}
        >
          Login with Google
        </S.GoogleButton>
      </S.Modal>
      <RegisterModal
        open={openRegister}
        handleClose={() => setOpenRegister(false)}
      />
    </div>
  );
}
