import { useState } from 'react';
import { paperProps } from 'utils/paperProps';
import {
  Menu, MenuItem, ListItemIcon, IconButton,
} from '@mui/material';
import { forumReplyDelete } from 'services/providers/pandlr.provider';
import { useSelector, useDispatch } from 'react-redux';
import { Delete, Reply, MoreVert } from '@mui/icons-material';
import { setQuoteReply, setSnackbar } from 'redux/slicers';
import FlagIcon from '@mui/icons-material/Flag';

export function ReplyMenu({ reply }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { topicDetails, textEditor: { editorId } } = useSelector((state) => state);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleForumReplyDelete = async () => {
    const result = await forumReplyDelete({ replyId: reply.id, groupId: topicDetails.groupId });
    if (result.success) {
      return dispatch(setSnackbar({ message: result.data.message, status: 'success' }));
    } return dispatch(setSnackbar({ message: result.data.message, status: 'error' }));
  };

  const handleQuote = (username, content) => {
    const tinyMCE = window.tinymce.get(editorId);
    tinyMCE.focus();
    const blockquote = content.indexOf('<blockquote>');
    if (blockquote !== -1) {
      const endBlockquote = content.indexOf('</blockquote>');
      content = content.slice(0, blockquote) + content.slice(endBlockquote + 13);
    }
    dispatch(setQuoteReply({ username, content }));
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="reply-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={paperProps}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          sx={{ display: { xs: 'block', sm: 'none' } }}
          onClick={() => {
            handleQuote(reply.replyAuthor.username, reply.content);
            handleClose();
          }}
        >
          <ListItemIcon>
            <Reply sx={{
              width: 20,
            }}
            />
          </ListItemIcon>
          @
          {reply.replyAuthor?.username}
        </MenuItem>
        <MenuItem
          onClick={() => {
            alert('Option not available yet');
            handleClose();
          }}
        >
          <ListItemIcon>
            <FlagIcon sx={{
              width: 20,
            }}
            />
          </ListItemIcon>
          Denunciar
        </MenuItem>
        {topicDetails.userCredentials?.isModerator
          && (
            <div>
              <MenuItem onClick={() => { handleForumReplyDelete(); handleClose(); }}>
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                Excluir
              </MenuItem>
            </div>
          )}
      </Menu>
    </>
  );
}
