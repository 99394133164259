import { useContext, useEffect } from 'react';
import { Reply, TopicNewReply, TopicPagination } from 'components';
import { getTopicReplies } from 'services/providers/pandlr.provider';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Box, Divider } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector, useDispatch } from 'react-redux';
import { setTopicPagination } from 'redux/slicers';
import { v4 as uuidv4 } from 'uuid';
import { useAutoScroll } from 'hooks';
import { TopicContext } from 'contexts';
import ReplySkeleton from '../Reply/ReplySkeleton';

export function TopicReplies() {
  const { id, pageNumber } = useParams();
  const { replies, setReplies } = useContext(TopicContext);
  const { pathname } = useLocation();
  const { topicPagination, topicDetails, infiniteScroll } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { autoScroll } = useAutoScroll();
  const navigate = useNavigate();

  const HAS_MORE = infiniteScroll && (!!topicPagination
    .totalPages === 0 || topicPagination.currentPage < topicPagination.totalPages);

  const handlePageChange = (page) => {
    if (!pathname.includes('/page/')) {
      return navigate(`${pathname}/page/${page}`);
    }
    return navigate(`${pathname.replace(`page/${pageNumber}`, `page/${page}`)}`);
  };

  const fetchTopicReplies = async (page) => {
    const result = await getTopicReplies(id, page);
    if (result.success) {
      const { items, ...paginationOptions } = result.data;

      setReplies((prevReplies) => {
        if (prevReplies.length > 70) {
          return [...prevReplies.slice(30), ...items];
        }
        return [...prevReplies, ...items];
      });
      return dispatch(setTopicPagination(paginationOptions));
    }
    return null;
  };

  useEffect(() => {
    fetchTopicReplies(pageNumber || 1);
    return () => setReplies([]);
  }, [pathname]);

  return (
    <div>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        mt="1rem"
        bgcolor="background.level2"
        borderRadius={1}
        overflow="hidden"
        boxShadow={(theme) => theme.shadows[1]}
      >
        {!replies.length && topicDetails.replies !== 0 && (Array(5).fill(0).map(() => (
          <div key={`reply-skeleton-${uuidv4()}`}>
            <ReplySkeleton />
            <Divider variant="middle" />
          </div>
        )))}

        <InfiniteScroll
          dataLength={replies.length}
          pageStart={0}
          next={() => fetchTopicReplies(topicPagination.currentPage + 1)}
          useWindow
          hasMore={HAS_MORE}
          loader={replies.length > 0 && (Array(5).fill(0).map(() => (
            <div key={`reply-skeleton-${uuidv4()}`}>
              <ReplySkeleton />
              <Divider variant="middle" />
            </div>
          )))}
        >
          {replies?.map((reply) => (
            <Reply key={`reply-${reply.id}`} reply={reply} />
          ))}
        </InfiniteScroll>
      </Box>
      <Box display="flex" justifyContent="flex-end" sx={{ mb: 1 }}>
        <TopicPagination onSelectPage={handlePageChange} />
      </Box>
      <TopicNewReply setReplies={setReplies} />
      {autoScroll}
    </div>
  );
}
