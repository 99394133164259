import { Box } from '@mui/material';
import { Container } from 'common/Container';

export function TopicContainer({ children }) {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginBottom={-100}
      marginTop={10}
    >
      <Container maxWidth={980}>
        {children}
      </Container>
    </Box>
  );
}
