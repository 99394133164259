import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserTopics } from 'services/providers/pandlr.provider';

import List from '@mui/material/List';

import { ForumTopicsSkeleton } from 'components/Forum/ForumTopicsSkeleton';
import { ForumSingleTopic } from 'components/Forum/ForumSingleTopic';
import InfiniteScroll from 'react-infinite-scroll-component';

export function UserTopics() {
  const [topics, setTopics] = useState([]);
  const { user } = useSelector((state) => state.userProfile);

  const paginationRef = useRef({
    currentPage: 1,
    totalPages: 10,
    totalItems: 1,
  });

  const fetchUserTopics = async (page) => {
    if (!user.id) return;

    const res = await getUserTopics(user.id, page);

    if (res.success) {
      const { items, ...paginationOptions } = res.data;
      paginationRef.current = paginationOptions;
      setTopics((prev) => {
        if (prev.length > 70) {
          return [...prev.slice(30), ...items];
        }
        return [...prev, ...items];
      });
    }
  };

  useEffect(() => {
    setTopics([]);
    fetchUserTopics(paginationRef.current.currentPage || 1);
    return () => setTopics([]);
  }, [user.id]);

  const HAS_MORE = !!paginationRef.current.totalPages === 0
    || paginationRef.current.currentPage < paginationRef.current.totalPages;

  return (
    <InfiniteScroll
      dataLength={topics.length}
      pageStart={0}
      next={() => fetchUserTopics(paginationRef.current.currentPage + 1)}
      useWindow
      hasMore={HAS_MORE}
      loader={topics.length > 0 && <ForumTopicsSkeleton />}
    >
      <List id="topic-list" sx={{ width: '100%', bgcolor: 'background.level2' }}>
        {!topics.length && <ForumTopicsSkeleton />}
        {topics?.map((topic) => (
          <ForumSingleTopic
            key={`topic-${topic.id}`}
            topic={topic}
            groupSlug={topic.group?.slug}
          />
        ))}
      </List>
    </InfiniteScroll>
  );
}
