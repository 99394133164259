import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setQuoteReply } from 'redux/slicers';
import {
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import parse from 'html-react-parser';
import * as DOMPurify from 'dompurify';
import { GifOptions } from 'components/Menus';
import { v4 as uuidv4 } from 'uuid';
import Embed from 'components/Embed/Embed';
import { MenuUserAvatar } from 'components/Menus/UserMenuAvatar';
import { useNavigate } from 'react-router-dom';
import { ReplyMenu } from './ReplyMenu';
import { PostContent } from '../../common/PostContent';

export function Reply({ reply }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cleanHtmlTopicContent = DOMPurify.sanitize(reply.content);
  const { editorId } = useSelector((state) => state.textEditor);
  const [showGifOptions, setShowGifOptions] = useState({
    show: false, image: '', UUID: '', positions: { top: '', left: '' },
  });

  const handleClick = (UUID) => {
    const image = document.getElementById(UUID);
    const rect = image.getBoundingClientRect();
    const positions = {
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    };
    setShowGifOptions({
      show: true, image: image.src, UUID, positions, element: image,
    });
  };

  const renderEmbed = useCallback((url) => <Embed url={url} />, []);
  const renderLink = useCallback((text) => <a href={text}>{text}</a>, []);

  let linksCounter = 0;

  const replyContent = parse(cleanHtmlTopicContent, {
    replace: (domNode) => {
      if (domNode.type === 'text' && /https?:\/\//.test(domNode.data)) {
        if (linksCounter >= 3) {
          return renderLink(domNode.data);
        }
        linksCounter += 1;
        return renderEmbed(domNode.data);
      }
      if (domNode.name === 'img' && domNode.attribs.src) {
        const UUID = uuidv4();
        domNode.attribs.id = `${UUID}`;
        domNode.attribs.style = 'cursor: pointer;';
        domNode.attribs.onClick = () => handleClick(UUID);
      }
      return domNode;
    },
  });

  const handleQuote = (username, content) => {
    const tinyMCE = window.tinymce.get(editorId);
    tinyMCE.focus();
    const blockquote = content.indexOf('<blockquote>');
    if (blockquote !== -1) {
      const endBlockquote = content.indexOf('</blockquote>');
      content = content.slice(0, blockquote) + content.slice(endBlockquote + 13);
    }
    dispatch(setQuoteReply({ username, content }));
  };

  const AVATAR_URL = `${process.env.REACT_APP_IMAGE_URL}${reply.replyAuthor?.avatar}`;

  return (
    <>
      <GifOptions
        showGifOptions={showGifOptions}
        setShowGifOptions={setShowGifOptions}
      />
      <Card
        sx={{
          backgroundColor: 'background.level2',
          backgroundImage: 'none',
          borderRadius: '0px',
          marginBottom: 0,
          maxHeight: 5000,
        }}
      >
        <CardHeader
          sx={{
            alignItems: 'flex-start',
            paddingBottom: { md: 0 },
          }}
          avatar={(
            <MenuUserAvatar
              user={reply.replyAuthor}
              src={AVATAR_URL}
              alt={reply.replyAuthor?.username}
              variant="rounded"
              sx={{
                width: { xs: 45, sm: 50, md: 75 },
                height: { xs: 45, sm: 50, md: 75 },
                marginTop: '3px',
                cursor: 'pointer',
              }}
            />
          )}
          action={(
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                cursor: 'pointer',
              }}
            >
              <Box
                sx={{
                  display: { xs: 'none', sm: 'flex' },
                  alignItems: 'center',
                  gap: '5px',
                }}
                onClick={() => handleQuote(reply.replyAuthor.username, reply.content)}
              >
                <ReplyIcon sx={{
                  width: 20,
                }}
                />
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body3"
                  color="text.secondary"
                >
                  @
                  {reply.replyAuthor?.username}
                </Typography>
              </Box>
              <ReplyMenu reply={reply} />
            </Box>
          )}
          title={(
            <Typography
              sx={{
                fontWeight: 'bold',
                mr: 1,
                cursor: 'pointer',
              }}
              component="span"
              variant="body1"
              color="text.primary"
              onClick={() => navigate(`/user/${reply.replyAuthor?.username}`)}
            >
              {reply.replyAuthor?.name}
            </Typography>
          )}
          subheader={(
            <Typography
              sx={{ display: { xs: 'block', md: 'inline' } }}
              component="span"
              variant="body3"
              color="text.secondary"
            >
              {reply.createdAt}
            </Typography>
          )}
        />
        <CardContent
          sx={{
            paddingTop: 0,
            transform: { xs: 'none', md: 'translateY(-20px)' },
          }}
        >
          <PostContent
            sx={{
              ml: { xs: 0, sm: 0, md: 12 },
              mt: 0,
              display: 'block',
            }}
            component="span"
            variant="body1"
            color="text.primary"
            id={`post-${reply.id}`}
          >
            {replyContent}
          </PostContent>
        </CardContent>
        <Divider variant="middle" />
      </Card>
    </>
  );
}
