import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/date';

export default function ForumBanCard({ ban }) {
  return (
    < >
      <Typography
        variant="body1"
        color="text.primary"
        component="span"
      >
        Usuário:
        {' '}
        <Link to={`/user/${ban.user.username}`}>
          @
          {ban.user.username}
        </Link>
      </Typography>
      <Typography
        variant="body1"
        color="text.primary"
        component="span"
      >
        Razão:
        {' '}
        {ban.reason}
      </Typography>
      <Typography
        variant="body1"
        color="text.primary"
        component="span"
      >
        Data do ban:
        {' '}
        {formatDate(ban.banDate)}
      </Typography>
      <Typography
        variant="body1"
        color="text.primary"
        component="span"
      >
        Banido por:
        {' '}
        <Link to={`/user/${ban.bannedByUser.username}`}>
          @
          {ban.bannedByUser.username}
        </Link>
      </Typography>
    </>
  );
}
